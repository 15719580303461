import React, { useState, useEffect } from 'react'
import axios from 'axios'
import api from 'api/api'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Badge from 'react-bootstrap/Badge'
import Alert from 'react-bootstrap/Alert'
import BaseDataInputs from '../BaseDataInputs'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'


export default function Identificacion(props) {
	const { config, tarea, onClose, onGuardar } = props

	const [loading, setLoading] = useState(true)
	const [cancelToken, setCancelToken] = useState(null)
	const [stock, setStock] = useState(null)
	const [data, setData] = useState({
		id: tarea?.id,
		nombre: tarea?.nombre ?? '',
		fechaParaCuando: tarea?.fechaParaCuando ?? '',
		asignadoId: tarea?.asignadoId ?? '',
		observacion: tarea?.observacion ?? ''
	})
	const [identificacion, setIdentificacion] = useState({
		propietarioId: tarea?.identificacion.propietarioId ?? config.propietarios[0].id,
		establecimientoId: tarea?.identificacion.establecimientoId ?? config.establecimientos[0].id,
		categoriaId: tarea?.identificacion.categoriaId ?? config.categorias[0].id,
		sexo: tarea?.identificacion.sexo ?? config.categorias[0].sexo,
		cantidad: tarea?.identificacion.cantidad ?? 1
	})

	const initialBaseInputData = tarea ? { nombre: tarea.nombre, fechaParaCuando: tarea.fechaParaCuando, asignadoId: tarea.asignadoId, observacion: tarea.observacion } : null

	// Crear un array por cada elemento de stock, con el nombre, nivel y total de cada elemento
	const crearArray = (elemento, nivel, nombreAnterior) => {
		// Si el elemento tiene subdivisiones, se llama recursivamente a la función para cada una de ellas
		if (elemento.subdivisiones) {
			return [
				// Se agrega el elemento actual al array resultante
				{
					nombre: nombreAnterior ? nombreAnterior + ", " + elemento.nombre : elemento.nombre,
					nivel: nivel,
					total: elemento.total,
					filtros: elemento.filtros
				},
				// Se concatena el array resultante con el array de las subdivisiones
				...elemento.subdivisiones.flatMap((sub) =>
					crearArray(sub, nivel + 1, nombreAnterior ? nombreAnterior + ", " + elemento.nombre : elemento.nombre)
				),
			]
		} else {
			// Si no tiene subdivisiones, se devuelve el elemento actual
			return [
				{
					nombre: nombreAnterior ? nombreAnterior + ", " + elemento.nombre : elemento.nombre,
					nivel: nivel,
					total: elemento.total,
					filtros: elemento.filtros
				},
			]
		}
	}

	const compararFiltros = (elemento) => {
		// Se obtiene el objeto filtros del elemento
		const filtros = elemento.filtros
		const categoriaId = Number(identificacion.categoriaId)
		// Se verifica que el filtro categoriaId sea igual al valor del select categoriaId
		if (filtros.categoriaId !== categoriaId) {
			return false
		}
		
		// Si la categoría corresponde a terneros o bubalinos, e verifica que el filtro sexo sea igual al valor del select sexo
		if ((filtros.categoriaId === 7 || filtros.categoriaId === 9) && filtros.sexo !== identificacion.sexo) {
			return false
		}
		// Si todas las condiciones se cumplen, se devuelve true
		return true
	}

	const verSiHayCambios = () => {
		let sinCambios = false

		if (tarea) {
			sinCambios = tarea.identificacion.propietarioId === Number(identificacion.propietarioId) && tarea.identificacion.establecimientoId === Number(identificacion.establecimientoId)
				&& tarea.identificacion.categoriaId === Number(identificacion.categoriaId) 
				&& Number(tarea.identificacion.cantidad) === Number(identificacion.cantidad)
		}

		return !sinCambios
	}

	const stockArray = stock?.flatMap((elemento) => crearArray(elemento, 0)) ?? []
	// La clase actualmente seleccionada por los select de categoría y sexo
	const seleccionadoIndex = stockArray.findIndex(compararFiltros)
	// Un mensaje de alerta en caso de tener indicado mayor cantidad que lo disponible en la clase seleccionada
	const alertText = Number(identificacion.cantidad) < 1 ? 'Se debe seleccionar al menos un animal' : (identificacion.cantidad <= (stockArray[seleccionadoIndex]?.total ?? 0) ? '' : 'No hay suficientes animales disponibles en la clase seleccionada.')
	const saveDisabled = data.nombre.trim() === '' || data.fechaParaCuando === '' || alertText
	const hayModificaciones = verSiHayCambios()

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		//fetchStock(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	useEffect(() => {
		if (cancelToken) {
			fetchStock(cancelToken)
		}
	}, [cancelToken, identificacion.propietarioId, identificacion.establecimientoId])

	const fetchStock = async (source) => {
		setLoading(true)
		try {
			const serverData = {
				propietarioId: ['in', [identificacion.propietarioId]],
				establecimientoId: ['in', [identificacion.establecimientoId]]
			}
			const { datos: respuesta, error } = await api.post('u/ver-stock-no-identificados', serverData, { cancelToken: source.token })
			if (!error) {
				setStock(respuesta)
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.log('fetchStock error', error)
			}
		}
		setLoading(false)
	}

	const saveBaseInputsData = (baseData) => {
		setData(prev => ({
			...prev,
			...baseData
		}))
	}

	const saveCategoriaData = (categoriaData) => {
		setIdentificacion(prev => ({
			...prev,
			...categoriaData
		}))
	}

	const handleChange = (valor, cual) => {
		setIdentificacion(prev => ({
			...prev,
			[cual]: valor
		}))
	}

	const handleSave = () => {
		const serverData = {
			tipo: 'identificacion',
			nombre: data.nombre,
			fechaParaCuando: data.fechaParaCuando,
			asignadoId: data.asignadoId,
			observacion: data.observacion,
			identificacion
		}

		if (tarea) {
			serverData.id = tarea.id
			/*
			serverData.listas[0].id = tarea.listas[0].id

			const yaExistentesIds = tarea.listas[0].animales.map((animal) => parseInt(animal.id))
			const seleccionadosIds = seleccionados.todos.map((animal) => parseInt(animal.id))
	
			const agregarIds = seleccionadosIds.filter((id) => !yaExistentesIds.includes(id))
			const eliminarIds = yaExistentesIds.filter((id) => !seleccionadosIds.includes(id))
	
			if (agregarIds.length > 0) {
				serverData.listas[0].agregarAnimalesIds = agregarIds
			}
	
			if (eliminarIds.length > 0) {
				serverData.listas[0].eliminarAnimalesIds = eliminarIds
			}
			*/
		}

		onGuardar(serverData)
		onClose()
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<BaseDataInputs
				initialData={initialBaseInputData}
				usuarios={config.usuarios}
				saveData={saveBaseInputsData}
			/>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col md="4">
							{loading ? (
								<LoadingSpinner />
							) : (
								<>
									<h5>Stock no identificados</h5>
									<Table size="sm" className="stock-no-identificados">
										<thead></thead>
										<tbody>
											{stockArray.map((clase, i) => {
												const trClass = seleccionadoIndex === i ? 'clase-seleccionada' : (seleccionadoIndex === i + 1 ? 'arriba-del-seleccionado' : '')
												return (
													<tr key={`clase-${i}`} style={{ fontSize: `${0.9 - (0.15 * clase.nivel)}em` }} className={trClass}>
														<td>- <span style={{ marginLeft: clase.nivel * 10 }}></span>{clase.nombre}</td>
														<td><Badge>{clase.total}</Badge></td>
													</tr>
												)
											})}
										</tbody>
									</Table>
								</>
							)}
						</Col>
						<Col md="8">
							<Row>
								<>
									<h5>Datos para identificación</h5>
									<Form.Group className="mb-3 col-12 col-sm-6" controlId="identificacion-propietario">
										<Form.Label>Propietario</Form.Label>
										<Form.Select
											size='sm'
											value={identificacion.propietarioId}
											onChange={(e) => handleChange(e.target.value, 'propietarioId')}
										>
											{config.propietarios.map((e) => {
												return (
													<option value={e.id} key={`propietario-${e.id}`}>
														{e.nombre}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3 col-12 col-sm-6" controlId="identificacion-propietario">
										<Form.Label>Establecimiento</Form.Label>
										<Form.Select
											size='sm'
											value={identificacion.establecimientoId}
											onChange={(e) => handleChange(e.target.value, 'establecimientoId')}
										>
											{config.establecimientos.map((e) => {
												return (
													<option value={e.id} key={`establecimiento-${e.id}`}>
														{e.nombre}
													</option>
												)
											})}
										</Form.Select>
									</Form.Group>
								</>
							</Row>
							<Row>
								<CategoriaAnimalesSelects
									opciones={config.categorias}
									initialCategoriaId={identificacion.categoriaId}
									initialSexo={identificacion.sexo}
									onSave={saveCategoriaData}
									inline
								/>
							</Row>
							<Row>
								<Col>
									<Form.Group className="mb-3" controlId="cantidad">
										<Form.Label className="mb-1"><strong>Cantidad</strong></Form.Label>
										<Form.Control
											type="number"
											size="sm"
											min="1"
											max={stockArray[seleccionadoIndex]?.total ?? 1}
											value={identificacion.cantidad}
											onChange={(e) => handleChange(e.target.value, 'cantidad')}
										/>
									</Form.Group>
								</Col>
							</Row>
							{alertText && (
								<Row>
									<Col>
										<Alert variant="warning">{alertText}</Alert>
									</Col>
								</Row>
							)}
						</Col>
					</Row>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={saveDisabled || !hayModificaciones}
							>
								{tarea ? 'Editar' : 'Crear'} tarea
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container >
	)
}