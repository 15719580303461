export const FETCH_DE_A_CUANTOS = 100
export const DEFAULT_CATEGORIA_ID = '1'
export const DEFAULT_SEXO = 'hembra'
export const OPCIONES_DE_A_CUANTOS = [100, 200, 500]
export const DEFAULT_COLS = [
	{
		label: 'DIOB',
		key: 'numChip',
		width: 150
	},
	{
		label: '# Car',
		key: 'numCaravana',
		width: 150
	},
	{
		label: 'Lote',
		key: 'lote',
	},
	{
		label: 'Categoría',
		key: 'categoria'
	},
	{
		label: 'Peso',
		key: 'peso'
	}
]

export const DEFAULT_ORDEN =  {
	campo: 'numCaravana',
	direccion: 'asc'
}

export const DEFAULT_SELECTED_COLS = [
	{
		label: 'Id',
		key: 'id'
	},
	{
		label: 'Lote',
		key: 'lote',
	},
	{
		label: '# Car',
		key: 'numCaravana'
	},
	{
		label: 'Categoría',
		key: 'categoria'
	},
	{
		label: 'Peso',
		key: 'peso'
	}
]

export const DEFAULT_SELECTED_ORDER_FIELD = 'numCaravana'