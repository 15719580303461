import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import { getShortDisplayDate } from 'utils/date'

export default function FichaSanitacionesTabContent(props) {
	const { sanitaciones } = props

	// Función para obtener la lista de medicamentos con sus dosis
	const getMedicamentosConDosis = (sanitacion) => {
		// Si no hay medicamentos o configMedicamentos, retorna un string vacío
		if (!sanitacion.medicamentos || !sanitacion.configMedicamentos) return '';

		try {
			// Parseamos el string JSON a objeto
			const medicamentosObj = JSON.parse(sanitacion.medicamentos);

			// Creamos un mapeo de id a nombre de medicamento
			const medicamentosMap = {};
			sanitacion.configMedicamentos.forEach(med => {
				medicamentosMap[med.id] = med.nombre;
			});

			// Construimos el string de medicamentos con sus dosis
			const medicamentosConDosis = Object.entries(medicamentosObj).map(([id, dosis]) => {
				const nombreMedicamento = medicamentosMap[id] || `Medicamento ${id}`;
				return `${nombreMedicamento}: ${dosis}`;
			}).join(', ');

			return medicamentosConDosis;
		} catch (error) {
			console.error('Error al procesar medicamentos:', error);
			return 'Error al procesar medicamentos';
		}
	};

	return (
		<div className="tab-content-wrapper">
			<h2>Sanitaciones</h2>
			{sanitaciones.length ? (
				<div className="col-12">
					<Table size="sm">
						<thead>
							<tr>
								<th>Fecha</th>
								<th>Medicamentos</th>
							</tr>
						</thead>
						<tbody>
							{
								sanitaciones.map((sanitacion, i) => {
									return <tr key={sanitacion.id}>
										<th>{getShortDisplayDate(sanitacion.fecha)}</th>
										<td>{getMedicamentosConDosis(sanitacion)}</td>
									</tr>
								})
							}
						</tbody>
					</Table>
				</div>
			) : (
				<Alert variant="info" className="mt-3">No hay sanitaciones del animal</Alert>
			)}
		</div>
	)
}