import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { InfoIcon } from 'components/InfoIcon'

export default function ListaAnimalesDeTarea(props) {
	const { animales, titulo = 'Animales', sinSeleccionar = false, onSeleccionar, onEliminarProcesado, onCancelarNuevoProcesado, onCancelarEliminarProcesado, loading,
		recienProcesados = [], recienEliminadoProcesadosIds = [], conPesoProcesado = false } = props

	return (
		<Card className="mt-3">
			<Card.Header as='h5'>{titulo}</Card.Header>
			<Card.Body style={{ overflow: 'scroll' }}>
				<Table striped bordered hover size="sm" style={{ minWidth: 500 }}>
					<thead>
						<tr>
							<th className="text-center"><InfoIcon text={'Animales que fueron procesados'} /></th>
							<th style={{ width: 150 }}>numCaravana</th>
							<th style={{ width: 150 }}>DIOB</th>
							<th>Peso</th>
							{conPesoProcesado ? <th>Peso act</th> : null}
							<th>Categoría</th>
							{!sinSeleccionar && (
								<th></th>
							)}
						</tr>
					</thead>
					<tbody style={loading ? { opacity: 0.3 } : null}>
						{animales.map((animal) => {
							const recienProcesado = recienProcesados.find(p => p.id === animal.id)

							const resaltarVerdeClaro = recienProcesado !== undefined
							const resaltarRojo = recienEliminadoProcesadosIds.includes(animal.id)
							let trClassName = ""
							if (resaltarVerdeClaro && resaltarRojo) {
								trClassName = 'bg-green fst-italic'
							} else if (resaltarVerdeClaro) {
								trClassName = 'bg-lightgreen fst-italic'
							} else if (resaltarRojo) {
								trClassName = 'bg-lightred fst-italic'
							}

							let fn = onSeleccionar
							let variant = "success"
							let iconClass = 'fa-plus'
							let title = ''

							if (resaltarVerdeClaro) {
								fn = onCancelarNuevoProcesado
								variant = 'secondary'
								iconClass = 'fa-times'
								title = 'Cancelar procesado'
							} else if (resaltarRojo) {
								fn = onCancelarEliminarProcesado
								variant = 'secondary'
								iconClass = 'fa-times'
								title = 'Cancelar eliminar datos anteriores'
							} else if (animal.procesado) {
								fn = onEliminarProcesado
								variant = 'tertiary'
								iconClass = 'fa-minus'
								title = 'Eliminar datos actuales'
							}

							const boton = <Button
								size="sm"
								onClick={(e) => fn(animal)}
								variant={variant}
								title={title}
							/*
								disabled={loading)}
							*/
							>
								<i className={"fa-solid " + iconClass}></i>
							</Button>

							const botonExtra = resaltarRojo ? <Button
								size="sm"
								onClick={(e) => onSeleccionar(animal)}
								variant="success"
								className="ms-2"
							/*
								disabled={loading)}
							*/
							>
								<i className={"fa-solid fa-plus"}></i>
							</Button> : null

							const tooltipContent = (
								<table className="text-start">
									<tr>
										<th>DIOB</th>
										<td>{animal.numChip || '-'}</td>
									</tr>
									<tr>
										<th>Caravana</th>
										<td>{animal.numCaravana || '-'}</td>
									</tr>
									<tr>
										<th>Tatuaje</th>
										<td>{animal.tatuaje || '-'}</td>
									</tr>
									<tr>
										<th>Raza</th>
										<td>{animal.raza || '-'}</td>
									</tr>
									<tr>
										<th>Sexo</th>
										<td>{animal.sexo}</td>
									</tr>
									<tr>
										<th>Color</th>
										<td>{animal.color || '-'}</td>
									</tr>
									<tr>
										<th>Carimbo</th>
										<td>{animal.carimbo || '-'}</td>
									</tr>
									{animal.peso && <tr><th>Peso</th><td>{animal.peso} kg</td></tr>}
									{animal.fechaNacimiento && <tr><th>Fecha nacimiento</th><td>{animal.fechaNacimiento}</td></tr>}
									{animal.lote && <tr><th>Lote</th><td><div style={{ width: 150 }}>{animal.lote}</div></td></tr>}
								</table>
							)

							return (
								<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 400 }}
									overlay={
										<Tooltip id={`tooltip-${animal.id}`}>
											{tooltipContent}
										</Tooltip>
									}
								>
									<tr
										key={animal.id}
										className={trClassName}
									>
										<td className="text-center">{animal.procesado ? <i className="fa-solid fa-circle-check fa text-success"></i> : '-'}</td>
										<td>{animal.numCaravana ?? '-'}</td>
										<td>{animal.numChip ?? '-'}</td>
										<td>{(conPesoProcesado && animal.pesoAnterior) ? animal.pesoAnterior : animal.peso}</td>
										{conPesoProcesado ? <td>{recienProcesado?.peso ? recienProcesado.peso : (animal.pesoAnterior ? animal.peso : '-')}</td> : null}
										<td>{animal.categoria.nombre}</td>
										{!sinSeleccionar && (
											<td className="text-center">
												{boton}
												{botonExtra}
											</td>
										)}
									</tr>
								</OverlayTrigger>
							)
						})}
					</tbody>
				</Table>
			</Card.Body >
		</Card >
	)
}