import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import '../reportes.css'

import api from 'api/api'
import TopHeader from 'components/TopHeader'

export default function ReportePariciones() {

	useEffect(() => {
		toast('Tareas')
	}, [])

	// render
	return (
		<Container fluid id="reporte-pariciones" className="vh-100">
			<TopHeader
				text="Reporte de pariciones"
			/>
			<Row>
				<Col>
					Contenido del reporte
				</Col>
			</Row>
		</Container>
	)
}
