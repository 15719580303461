import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { numberWithSeparator } from 'utils/numberWithSeparator'

// Componente para los contadores de lluvia con diseño de cards
const ContadoresLluvia = ({
	loading,
	filtros,
	totalLluviaAnual,
	totalLluviaUltimos12Meses,
	periodoUltimos12Meses
}) => {
	return (
		<Row className="mb-4">
			<Col md={6}>
				<Card className="h-100 shadow-sm">
					<Card.Body className="text-center">
						<Card.Title className="text-primary mb-3">Lluvia total {filtros.anho}</Card.Title>
						<div className="display-4 mb-3">
							{loading ? (
								<div className="text-center">
									<LoadingSpinner />
									<p className="text-muted small mt-2">Calculando...</p>
								</div>
							) : (
								<strong>{numberWithSeparator(totalLluviaAnual.toFixed(0))}</strong>
							)}
							<span className="h5 text-muted"> ml</span>
						</div>
						<p className="text-muted mb-0">
							Total acumulado durante el año {filtros.anho}
						</p>
					</Card.Body>
				</Card>
			</Col>

			<Col md={6}>
				<Card className="h-100 shadow-sm">
					<Card.Body className="text-center">
						<Card.Title className="text-primary mb-3">Últimos 12 meses</Card.Title>
						<div className="display-4 mb-3">
							{loading ? (
								<div className="text-center">
									<LoadingSpinner />
									<p className="text-muted small mt-2">Calculando...</p>
								</div>
							) : (
								<strong>{numberWithSeparator(totalLluviaUltimos12Meses.toFixed(0))}</strong>
							)}
							<span className="h5 text-muted"> ml</span>
						</div>
						{periodoUltimos12Meses && !loading && (
							<p className="text-muted mb-0">
								<i className="fas fa-calendar-alt me-1"></i> {periodoUltimos12Meses}
							</p>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	)
}

export default ContadoresLluvia