import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { numberWithSeparator } from 'utils/numberWithSeparator'

// Función auxiliar para convertir números a string de manera segura
const safeNumberToString = (value) => {
	if (value === undefined || value === null) return '0'
	return value.toString()
}

const ReproduccionCard = ({ reproduccionData, nombreEstablecimiento }) => {
	const servidas = reproduccionData?.servidas || { anhoActual: 0, anhoPasado: 0 }
	const prenadas = reproduccionData?.prenadas || { anhoActual: 0, anhoPasado: 0 }
	const nacidos = reproduccionData?.nacidos || { anhoActual: 0, anhoPasado: 0 }

	return (
		<Card className="shadow-sm">
			<Card.Header className="bg-primary text-white">
				<h5 className="mb-0">
					Reproducción
					{nombreEstablecimiento && (
						<small className="ms-2">({nombreEstablecimiento})</small>
					)}
				</h5>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col xs={4} className="text-center mb-3">
						<div className="stat-circle mx-auto mb-2 d-flex align-items-center justify-content-center bg-light rounded-circle" style={{ width: '80px', height: '80px' }}>
							<i className="fa fa-venus-mars fa-2x text-dark"></i>
						</div>
						<h3>{numberWithSeparator(safeNumberToString(servidas.anhoActual))}</h3>
						<p className="text-muted mb-0">Servidas</p>
						<small className="text-muted">
							{numberWithSeparator(safeNumberToString(servidas.anhoPasado))} año pasado
						</small>
					</Col>
					<Col xs={4} className="text-center mb-3">
						<div className="stat-circle mx-auto mb-2 d-flex align-items-center justify-content-center bg-light rounded-circle" style={{ width: '80px', height: '80px' }}>
							<span className="fa-stack fa-2x">
								<i className="fa fa-cow fa-stack-1x text-dark"></i>
								<i className="fa fa-clock fa-stack-1x text-primary" style={{ transform: 'scale(0.5) translate(45%, -55%)' }}></i>
							</span>
						</div>
						<h3>{numberWithSeparator(safeNumberToString(prenadas.anhoActual))}</h3>
						<p className="text-muted mb-0">Preñadas</p>
						<small className="text-muted">
							{numberWithSeparator(safeNumberToString(prenadas.anhoPasado))} año pasado
						</small>
					</Col>
					<Col xs={4} className="text-center mb-3">
						<div className="stat-circle mx-auto mb-2 d-flex align-items-center justify-content-center bg-light rounded-circle" style={{ width: '80px', height: '80px' }}>
							<span className="fa-stack fa-2x">
								<i className="fa fa-cow fa-stack-1x text-dark"></i>
								<i className="fa fa-plus-circle fa-stack-1x text-primary" style={{ transform: 'scale(0.5) translate(45%, -55%)' }}></i>
							</span>
						</div>
						<h3>{numberWithSeparator(safeNumberToString(nacidos.anhoActual))}</h3>
						<p className="text-muted mb-0">Nacidos</p>
						<small className="text-muted">
							{numberWithSeparator(safeNumberToString(nacidos.anhoPasado))} año pasado
						</small>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default ReproduccionCard