import React, { useState, useEffect, useRef, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'
import { toast } from 'react-toastify'
import './tareas.css'

import api from 'api/api'
import { TIPOS_TAREAS, TIPOS_EVENTOS } from 'config/tareas'
import TareasPendientesTabContent from './TareasPendientesTabContent'
import TareasHistorialTabContent from './TareasHistorialTabContent'
import { FiltrosFormWrapper } from 'components/FiltrosFormWrapper'
import { ConfigContext } from 'contexts/config/ConfigContext'
import { debounce } from 'utils/debounce'
import MostrarAccionEditar from './MostrarAccionEditar'
import MostrarAccionVer from './MostrarAccionVer'
import MostrarAccionProcesar from './MostrarAccionProcesar'
import MostrarAccionCrear from './MostrarAccionCrear'
import MostrarAccionEliminar from './MostrarAccionEliminar'
import TopHeader from 'components/TopHeader'


export default function Tareas() {
	const [tab, setTab] = useState('')
	const [loading, setLoading] = useState(false)
	const { data: config, loading: loadingConfig, error } = useContext(ConfigContext)

	const [filtros, setFiltros] = useState({
		nombre: [''],
		tipo: '',
		creadorId: '',
		asignadoId: '',
	})
	const filtrosRef = useRef(filtros)
	const [busqueda, setBusqueda] = useState('')
	const [queHacer, setQueHacer] = useState({
		accion: '', // ver, editar, procesar, eliminar
		tipo: '', // ingreso, adquisicion, pesaje, ...
		tareaId: null
	})
	const [eliminarTarea, setEliminarTarea] = useState(null)

	const tiposTareasPosibles = TIPOS_TAREAS
	const tiposEventosPosibles = TIPOS_EVENTOS
	const valoresFiltroTipoTareas = [
		{ label: 'Todos', value: '' },
		...tiposTareasPosibles,
		...tiposEventosPosibles
	]
	const valoresFiltroUsuario = [
		{ label: 'Todos', value: '' },
		...config.usuarios.map(usuario => ({ label: `${usuario.nombre} ${usuario.apellido}`, value: usuario.id })),
	]

	useEffect(() => {

		handleTabSelect('pendientes')
	}, [])

	const handleBusqueda = (value) => {
		handleChangeFiltroNombre(value)
	}

	const debouceFiltroBusqueda = React.useCallback(
		debounce(handleBusqueda, 400),
		[]
	)

	useEffect(() => {
		if (busqueda !== filtros.nombre[0]) {
			debouceFiltroBusqueda(busqueda)
		}
	}, [busqueda])

	const handleChangeFiltroNombre = (valor) => {
		const nuevo = { ...filtrosRef.current }
		nuevo.nombre = [valor]
		setFiltros(prev => (nuevo))
	}

	const handleChange = (valor, cual) => {
		setFiltros(prev => ({
			...filtrosRef.current,
			[cual]: valor
		}))
	}

	useEffect(() => {
		filtrosRef.current = filtros
	}, [filtros])

	const handleTabSelect = (tabKey) => {
		setTab(tabKey)
		handleChange(tabKey === 'historial', 'completada')
	}

	const handleMostrarCrearTarea = (tipo) => {
		setQueHacer({
			accion: 'crear',
			tipo,
			tareaId: null
		})
	}

	const handleMostrarCrearEvento = (tipo) => {
		setQueHacer({
			accion: 'crear',
			tipo,
			tareaId: null
		})
	}

	const handleVerTarea = (tareaId, tipo) => {
		setQueHacer({
			accion: 'ver',
			tipo,
			tareaId
		})
	}

	const handleMostrarProcesarTarea = (tareaId, tipo) => {
		setQueHacer({
			accion: 'procesar',
			tipo,
			tareaId
		})
	}

	const handleMostrarEditarTarea = (tareaId, tipo) => {
		setQueHacer({
			accion: 'editar',
			tipo,
			tareaId
		})
	}

	const handleMostrarEliminarTarea = (tarea, tipo) => {
		setEliminarTarea(tarea)
	}

	const handleCloseMostrarAccion = () => {
		setQueHacer({
			accion: '', // ver, editar, procesar, eliminar
			tipo: '', // ingreso, adquisicion, pesaje, ...
			tareaId: null
		})
	}

	const handleCloseAccionEliminar = () => {
		setEliminarTarea(null)
	}

	const handleConfirmarEliminar = () => {
		console.log('llamando al server para eliminar', eliminarTarea)
	}

	const subirDocumentos = async (archivos) => {
		const addedKeys = {}
		for (var key in archivos) {
			try {
				let value = null
				if (archivos[key] !== null) {
					const serverData = new FormData()
					serverData.append('documento', archivos[key])
	
					const { datos: respuesta, msg } = await api.post('u/documentos', serverData)
					value = respuesta.id
				}
				
				addedKeys[key] = value
			} catch (error) {
				toast.error('Error ')
			}
		}

		return addedKeys
	}

	const handleGuardarTarea = async (body) => {
		try {
			setLoading(true)

			// En caso de ser necesario, primero sube los archivos, luego crea o edita la tarea
			let addedKeys = {}
			if (body?.archivos && Object.keys(body.archivos).length) {
				addedKeys = await subirDocumentos(body.archivos)
			}
			
			if (body.salida) {
				body.salida = { ...body.salida, ...addedKeys }
			}

			if (body.adquisicion) {
				body.adquisicion = { ...body.adquisicion, ...addedKeys }
			}

			if (body.tipo === 'mortandad') {
				for (let i = 0; i < body.listas[0].marcarProcesados.length; i++) {
					const serverData = new FormData()
					
					if (body.listas[0].marcarProcesados[i].foto) {
						serverData.append('foto', body.listas[0].marcarProcesados[i].foto)

						const { datos, msg } = await api.post('u/multimedia', serverData)

						body.listas[0].marcarProcesados[i].multimediasIds = [datos.id]
					}
				}
			}

			let respuesta, msg
			if (body.id) {
				({ datos: respuesta, msg } = await api.put(`u/tareas/${body.id}`, body))
			} else {
				({ datos: respuesta, msg } = await api.post('u/tareas', body))
			}

			toast.success(msg)
		} catch (error) {
			toast.error('Error de conexión')
		} finally {
			setLoading(false)
		}
	}

	const handleFinalizarTarea = async (id, comentarioEncargado) => {
		try {
			setLoading(true)

			const finalizarData = {
				id,
				comentarioEncargado,
				completada: true
			}

			const { datos: respuesta, msg } = await api.put(`u/tareas/${id}`, finalizarData)
			toast.success(msg)
		} catch (error) {
			toast.error('Error de conexión')
		} finally {
			setLoading(false)
		}
	}

	// render
	return (
		<Container fluid id="tareas" className="vh-100">
			<TopHeader
				iconName="list-check"
				text="Tareas"
			/>
			<Row>
				<Col>
					<DropdownButton
						as={ButtonGroup}
						title="Nueva tarea"
						id="btn-crear-tarea"
					>
						{tiposTareasPosibles.map((b, i) => {
							return (
								<Dropdown.Item
									onClick={() => handleMostrarCrearTarea(b.value)}
									key={b.value}
									eventKey={b.value}
								>
									{b.label}
								</Dropdown.Item>
							)
						})}
					</DropdownButton>
					<DropdownButton
						as={ButtonGroup}
						title="Nuevo evento"
						id="btn-crear-evento"
						className="ms-3"
						variant='outline-primary'
					>
						{tiposEventosPosibles.map((b, i) => {
							return (
								<Dropdown.Item
									onClick={() => handleMostrarCrearEvento(b.value)}
									key={b.value}
									eventKey={b.value}
								>
									{b.label}
								</Dropdown.Item>
							)
						})}
					</DropdownButton>
				</Col>
			</Row>
			<Row>
				<Col>
					<FiltrosFormWrapper>
						<Form.Group className="mb-3" controlId="filtro-nombre">
							<Form.Label>Nombre</Form.Label>
							<Form.Control
								size='sm'
								value={busqueda}
								onChange={(e) => setBusqueda(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="filtro-tipo">
							<Form.Label>Tipo</Form.Label>
							<Form.Select
								size='sm'
								value={filtros.tipo}
								onChange={(e) => handleChange(e.target.value, 'tipo')}
							>
								{valoresFiltroTipoTareas.map((e) => {
									return (
										<option value={e.value} key={e.value}>
											{e.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3" controlId="filtro-creadorId">
							<Form.Label>Creador</Form.Label>
							<Form.Select
								size='sm'
								value={filtros.creadorId}
								onChange={(e) => handleChange(e.target.value, 'creadorId')}
							>
								{valoresFiltroUsuario.map((e) => {
									return (
										<option value={e.value} key={`creador-${e.value}`}>
											{e.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
						<Form.Group className="mb-3" controlId="filtro-asignadoId">
							<Form.Label>Asignado</Form.Label>
							<Form.Select
								size='sm'
								value={filtros.asignadoId}
								onChange={(e) => handleChange(e.target.value, 'asignadoId')}
							>
								{valoresFiltroUsuario.map((e) => {
									return (
										<option value={e.value} key={`asignado-${e.value}`}>
											{e.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
					</FiltrosFormWrapper>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Tabs
						id="tareas-nav"
						activeKey={tab}
						onSelect={(t) => handleTabSelect(t)}
						className="mb-3"
					>
						<Tab eventKey="pendientes" title="Tareas pendientes" mountOnEnter={true} unmountOnExit={true}>
							<TareasPendientesTabContent
								active={tab === 'pendientes'}
								loading={loading}
								filtros={filtros}
								handleVer={handleVerTarea}
								handleProcesar={handleMostrarProcesarTarea}
								handleEditar={handleMostrarEditarTarea}
								handleEliminar={handleMostrarEliminarTarea}
							/>
						</Tab>
						<Tab eventKey="historial" title="Historial" mountOnEnter={true} unmountOnExit={true}>
							<TareasHistorialTabContent
								active={tab === 'historial'}
								loading={loading}
								filtros={filtros}
								handleVer={handleVerTarea}
							/>
						</Tab>
					</Tabs>
				</Col>
			</Row>
			{!loadingConfig && queHacer.accion === 'crear' ? (
				<MostrarAccionCrear
					config={config}
					tipo={queHacer.tipo}
					onClose={handleCloseMostrarAccion}
					onGuardar={handleGuardarTarea}
				/>
			) : null}
			{!loadingConfig && queHacer.accion === 'ver' && queHacer.tareaId ? (
				<MostrarAccionVer config={config} tipo={queHacer.tipo} tareaId={queHacer.tareaId} onClose={handleCloseMostrarAccion} />
			) : null}
			{!loadingConfig && queHacer.accion === 'editar' && queHacer.tareaId ? (
				<MostrarAccionEditar
					config={config}
					tipo={queHacer.tipo}
					tareaId={queHacer.tareaId}
					onClose={handleCloseMostrarAccion}
					onGuardar={handleGuardarTarea}
				/>
			) : null}
			{!loadingConfig && queHacer.accion === 'procesar' && queHacer.tareaId ? (
				<MostrarAccionProcesar
					config={config}
					tareaId={queHacer.tareaId}
					tipo={queHacer.tipo}
					onClose={handleCloseMostrarAccion}
					onGuardar={handleGuardarTarea}
					onFinalizarTarea={handleFinalizarTarea}
				/>
			) : null}
			{eliminarTarea !== null ? (
				<MostrarAccionEliminar tarea={eliminarTarea} onClose={handleCloseAccionEliminar} onDelete={handleConfirmarEliminar} />
			) : null}
		</Container>
	)
}
