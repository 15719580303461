import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Datetime from 'react-datetime'
import WizardStepFooter from 'components/Wizard/WizardStepFooter'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'
import { DEFAULT_CATEGORIA_ID, DEFAULT_SEXO } from 'config/seleccionAnimales'
import { toast } from 'react-toastify'
import { handleChangeFecha } from 'utils/date'

export default function DefinicionAnimales(props) {
	const { config, data: originalData = {}, isCompleted: isOriginallyCompleted, updateData, step, numSteps, prevStep, enableSave, disableFinish, warningText } = props

	const [showWarning, setShowWarning] = useState(warningText !== '')

	const defaultCategoriaId = DEFAULT_CATEGORIA_ID
	const defaultSexo = DEFAULT_SEXO

	/*
	 * data.listas = [
		{
			categoriaId: int,
			sexo: str,
			animales: []
		}
	 ]
	*/
	const [data, setData] = useState({ listas: JSON.parse(JSON.stringify(originalData?.listas)) })
	const [seleccion, setSeleccion] = useState({
		categoriaId: defaultCategoriaId,
		sexo: defaultSexo
	})

	const verSiHayCambios = () => {
		let cambios = false

		console.log('data', data)
		console.log('originalData', originalData)

		let verificarCambios = false
		const listasOriginalesIds = []
		originalData?.listas.forEach(l => {
			if (l.id) {
				verificarCambios = true
				listasOriginalesIds.push(l.id)
			}
		})


		if (verificarCambios) {
			const listasIds = []
			for (let l of data.listas) {
				if (l.id) {
					listasIds.push(l.id)
				} else {
					// si no hay id, entonces es una lista nueva
					cambios = true
					break
				}
			}

			if (listasIds.length !== listasOriginalesIds.length) {
				cambios = true
			} else {
				// queda verificar si si están con tarea de identificación y su fecha
				if (!cambios) {
					for (let lista of data.listas) {
						const listaOriginal = originalData.listas.find((l) => l.id === lista.id)
						if (Number(listaOriginal.cantidad) !== Number(lista.cantidad)) {
							cambios = true
						} else {
							if (listaOriginal.tipoIdentificacion !== lista.tipoIdentificacion || (lista.tipoIdentificacion === 'programada' && lista.fechaTareaIdentificacion.substr(0, 10) !== listaOriginal.fechaTareaIdentificacion.substr(0, 10))) {
								cambios = true
							}
						}
					}
				}
			}
		} else {
			cambios = true
		}

		return cambios
	}

	const verSiCompleto = () => {
		let completo = true

		if (data.listas.length > 0) {
			data.listas.forEach(lista => {
				if (Number(lista.cantidad) <= 0) {
					completo = false
				} else if (lista.tipoIdentificacion === 'programada' && lista.fechaTareaIdentificacion === '') {
					completo = false
				}
			})
		} else {
			completo = false
		}

		return completo
	}

	const hayModificaciones = verSiHayCambios()
	const isCompleted = verSiCompleto()

	const onSaveSelectCategoria = (categoriaData) => {
		setSeleccion(categoriaData)
	}

	const handleFooterAction = (direction, goToNext = false) => {
		if (direction === 'back') {
			prevStep()
		} else {
			updateData(data, isCompleted, goToNext)
		}
	}

	const handleAgregarLista = () => {
		// Verifica si no hay ya una lista igual
		const existente = data.listas.find(lista => {
			return Number(lista.categoriaId) === Number(seleccion.categoriaId)
				&& lista.sexo === seleccion.sexo
		})

		if (!existente) {
			const listas = [...data.listas]
			const nueva = {
				categoriaId: seleccion.categoriaId,
				sexo: seleccion.sexo,
				cantidad: 1,
				tipoIdentificacion: 'sin-identificacion',
				fechaTareaIdentificacion: ''
			}

			listas.push(nueva)

			setData(prev => ({ ...prev, listas }))
		} else {
			toast.warning('Ya existe una lista con estas características. Todas deben ser distintas.')
		}
	}

	const handleChangeListaData = (valor, cual, listaIndex) => {
		const listas = [...data.listas]
		listas[listaIndex][cual] = valor

		setData(prev => ({ ...prev, listas }))
	}

	const handleRemoveLista = (listaIndex) => {
		const listas = data.listas.filter((lista, i) => i !== listaIndex)

		setData(prev => ({ ...prev, listas }))
	}

	return (
		<>
			<Card>
				<Card.Body>
					<Row>
						<CategoriaAnimalesSelects
							opciones={config.categorias}
							initialCategoriaId={seleccion.categoriaId}
							initialSexo={seleccion.sexo}
							onSave={onSaveSelectCategoria}
							inline
						/>
					</Row>
					<Row>
						<Col>
							<Button
								className="my-1"
								onClick={() => handleAgregarLista()}
							>
								<i className="fa fa-plus-circle"></i> Agregar lista
							</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							{showWarning ? (
								<Alert
									variant="danger"
									className="my-2"
									dismissible
									onClose={() => setShowWarning(false)}
								>
									<i className="fa fa-exclamation-triangle me-2"></i> {warningText}
								</Alert>
							) : ''}
							<h4 className="mb-0 mt-3">Listas de animales</h4>
							{data.listas.length ? (
								<Table className="lista-seleccion-animales adquisicion table-hover pt-0">
									<thead>
										<tr>
											<th>Cantidad</th>
											<th>Categoría</th>
											<th>Sexo</th>
											<th></th>
											<th>Fecha identificación</th>
											<th>Eliminar</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{data.listas.map((lista, i) => {
											const categoria = config.categorias.find(categoria => Number(categoria.id) === Number(lista.categoriaId))
											const cantidad = Number(lista.cantidad)

											let completaIcon = ''
											if (cantidad) {
												if (lista.tipoIdentificacion === 'sin-identificacion' || (lista.tipoIdentificacion === 'programada' && lista.fechaTareaIdentificacion !== '')) {
													completaIcon = <i className="fas fa-check-circle text-success" title="Lista completa" />
												}
											}

											return (
												<tr key={`lista-${i}`} className="definicion-animales-lista">
													<td className="cantidad">
														<Form.Control
															value={lista.cantidad}
															onChange={(e) => handleChangeListaData(e.target.value, 'cantidad', i)}
															type="number"
															size="sm"
														/>
													</td>
													<td className="categoria">
														{categoria.nombre}
													</td>
													<td className="sexo">
														{lista.sexo}
													</td>
													<td>
														<Form.Group controlId={`check-identificados-${i}`}>
															<Form.Check
																type="checkbox"
																label="¿Identificar?"
																checked={lista.tipoIdentificacion === 'programada'}
																onChange={(e) => handleChangeListaData(e.target.checked ? 'programada' : 'sin-identificacion', 'tipoIdentificacion', i)}
															/>
														</Form.Group>
													</td>
													<td className="lista-fecha-identificacion">
														<Form.Group controlId={`txtFechaTareaIdentificacion-${i}`}>
															<Datetime
																dateFormat="YYYY-MM-DD"
																timeFormat={false}
																closeOnSelect={true}
																strictParsing={false}
																inputProps={{
																	id: `txtFechaTareaIdentificacion-${i}`,
																	placeholder: "YYYY-MM-DD",
																	className: 'form-control form-control-sm',
																	disabled: lista.tipoIdentificacion === 'sin-identificacion'
																}}
																value={lista.fechaTareaIdentificacion}
																onChange={(date) => handleChangeFecha(date, handleChangeListaData, 'fechaTareaIdentificacion', i)}
															/>
														</Form.Group>
													</td>
													<td className="acciones">
														<i className="fas fa-trash-alt lista-eliminar" title="Eliminar lista" onClick={() => handleRemoveLista(i)} />
													</td>
													<td className="lista-completa">
														{completaIcon}
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							) : (
								<Alert variant="warning">Se debe tener al menos una lista de animales.</Alert>
							)}
						</Col>
					</Row>
				</Card.Body>
				<WizardStepFooter
					step={step}
					numSteps={numSteps}
					handleAction={handleFooterAction}
					alreadyCompleted={isOriginallyCompleted}
					completed={isCompleted}
					modified={hayModificaciones}
					enableSave={enableSave}
					disableFinish={disableFinish}
				/>
			</Card>
		</>
	)
}