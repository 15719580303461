import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import BaseDataMostrar from '../BaseDataMostrar'
import { InfoIcon } from 'components/InfoIcon'

export default function PesajeVer(props) {
	const { config, tarea } = props

	const animalesProcesados = tarea.listas[0].animales.filter((a) => a.procesado === 1)

	let sum = 0
	let min = 0
	let max = 0
	let sumaCuadrados = 0
	let promedio = 0
	let stdDev = 0
	if (animalesProcesados.length) {
		min = Infinity
		max = -Infinity

		animalesProcesados.forEach((a) => {
			const peso = parseFloat(a.peso) || 0
			sum += peso
			sumaCuadrados += peso * peso
			min = Math.min(min, peso)
			max = Math.max(max, peso)
		})
	
		const cantidad = animalesProcesados.length || 1
		promedio = sum / cantidad
		const varianza = sumaCuadrados / cantidad - promedio * promedio
		stdDev = Math.sqrt(varianza)
	}

	const animalesOrdenados = [...tarea.listas[0].animales].sort((a, b) => { return a.eliminadoAlProcesar - b.eliminadoAlProcesar })

	return (
		<Container>
			<Row>
				<Col>
					<h3>{tarea.nombre}</h3>
				</Col>
			</Row>
			<BaseDataMostrar
				usuarios={config.usuarios}
				data={{
					nombre: tarea.nombre,
					fechaParaCuando: tarea.fechaParaCuando,
					asignadoId: tarea.asignadoId || '',
					observacion: tarea.observacion
				}}
			/>
			{tarea.comentarioEncargado && <Alert variant="info" className="mt-3">{tarea.comentarioEncargado}</Alert>}
			<Card className="mt-3">
				<Card.Body>
					<Card.Title>Resumen</Card.Title>
					<Row>
						<Col md="6">
							<Table>
								<tbody>
									<tr>
										<th>Total procesados</th>
										<td>{`${tarea.listas[0].cantidadAnimalesYaProcesados} / ${tarea.listas[0].cantidadAnimales}`}</td>
									</tr>
									<tr>
										<th>Promedio</th>
										<td>{promedio.toFixed(2)}</td>
									</tr>
									<tr>
										<th>Min</th>
										<td>{min}</td>
									</tr>
									<tr>
										<th>Máx</th>
										<td>{max}</td>
									</tr>
									<tr>
										<th>Desviación estándar</th>
										<td>{stdDev.toFixed(2)}</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col>
							<Card.Title>Cantidad de animales: <strong>{tarea.listas[0].cantidadAnimales}</strong></Card.Title>
							<Card.Subtitle>Cantidad de procesados: <strong>{tarea.listas[0].cantidadAnimalesYaProcesados}</strong></Card.Subtitle>
							<Table responsive>
								<thead>
									<tr>
										<th>#</th>
										<th>Procesado</th>
										<th>DIOB</th>
										<th>Núm. caravana</th>
										<th>Carimbo</th>
										<th>Raza</th>
										<th>Sexo</th>
										<th>Peso inicial</th>
										<th>Peso anterior</th>
										<th>Peso</th>
										<th><div style={{ width: 70 }}>GDP<br/>Actual <InfoIcon><strong>Ganancia Diaria de Peso Actual.</strong><br/>La ganancia de peso diaria en kg, desde el pesaje anterior.</InfoIcon></div></th>
										<th><div style={{ width: 70 }}>Ganancia<br/>Total <InfoIcon><strong>Ganancia total de peso.</strong><br/>La ganancia de peso total en kg, desde el peso inicial.</InfoIcon></div></th>
									</tr>
								</thead>
								<tbody>
									{animalesOrdenados.map((animal, i) => {
										const gananciaPesoTotal = animal.peso - (animal.pesoInicial ? animal.pesoInicial : 0)
										let gananciaPesoActual = 0
										if (animal.pesoAnterior) {
											gananciaPesoActual = (animal.peso - animal.pesoAnterior) / animal.numDiasPesajeAnterior
										}

										const className = animal.eliminadoAlProcesar ? 'animal-eliminado-al-procesar' : (animal.agregadoAlProcesar ? 'animal-agregado-al-procesar' : '')
										return (
											<tr key={animal.id} className={className}>
												<td>{i + 1}</td>
												<td className="text-center">{animal.procesado ? <i className="fa-solid fa-circle-check fa text-success"></i> : '-'}</td>
												<td>{animal['numChip'] || '-'}</td>
												<td>{animal['numCaravana'] || '-'}</td>
												<td>{animal.carimbo}</td>
												<td>{animal.raza}</td>
												<td>{animal.sexo}</td>
												<td>{animal.pesoInicial || '-'}</td>
												<td>{animal.pesoAnterior || '-'}</td>
												<td>{animal.peso || '-'}</td>
												<td>{gananciaPesoActual ? gananciaPesoActual.toFixed(2) : '-'}</td>
												<td>{gananciaPesoTotal ? gananciaPesoTotal.toFixed(2) : '-'}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container >
	)
}