import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import Accordion from 'react-bootstrap/Accordion'
import FileUpload from 'components/FileUpload/FileUpload'
import { InfoIcon } from 'components/InfoIcon'
import api from 'api/api'
import Ejemplo from 'assets/procesar-animales.png'

const ModalImportarExcel = (props) => {
	const { onClose, onSeleccionarAnimales } = props

	const [selectedFile, setSelectedFile] = useState(null)
	const [processedData, setProcessedData] = useState(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState('')
	const [selectedAnimals, setSelectedAnimals] = useState({})
	const [stats, setStats] = useState(null)
	const [permitirRepetidos, setPermitirRepetidos] = useState(false)
	const [selectedGroups, setSelectedGroups] = useState(new Set())

	const handleFileUpdate = async (files, permitirRepetidosValue = permitirRepetidos) => {
		if (files.length === 0) {
			setSelectedFile(null)
			setProcessedData(null)
			setStats(null)
			setError('')
			return
		}

		setSelectedFile(files[0])
		setError('')

		try {
			setLoading(true)
			const serverData = new FormData()
			serverData.append('animales', files[0])
			serverData.append('permitirIdentificacionesRepetidas', permitirRepetidosValue)

			const { datos } = await api.post('u/procesar-animales', serverData)

			const { animales, totalAgregables, totalNoAgregables } = datos

			setStats({
				totalAgregables,
				totalNoAgregables
			})

			setProcessedData(animales)

			const initialSelection = {}
			const initialGroups = new Set()

			animales.forEach((animal, index) => {
				if (animal.agregable && !animal.grupo) {
					initialSelection[index] = true
				} else if (animal.agregable && animal.grupo) {
					// Por defecto, seleccionamos el primer animal de cada grupo
					if (!initialGroups.has(animal.grupo)) {
						initialSelection[index] = true
						initialGroups.add(animal.grupo)
					}
				}
			})

			setSelectedAnimals(initialSelection)
			setSelectedGroups(initialGroups)
		} catch (error) {
			setError('Error al procesar el archivo')
			setProcessedData(null)
			setStats(null)
		} finally {
			setLoading(false)
		}
	}

	const handlePermitirRepetidosChange = (e) => {
		const newValue = e.target.checked
		setPermitirRepetidos(newValue)
		if (selectedFile) {
			handleFileUpdate([selectedFile], newValue)
		}
	}

	const handleCheckboxChange = (index) => {
		const animal = processedData[index]

		if (animal.grupo) {
			setSelectedAnimals(prev => {
				const newSelection = { ...prev }

				if (prev[index]) {
					// Si estamos deseleccionando
					delete newSelection[index]
					setSelectedGroups(prevGroups => {
						const newGroups = new Set(prevGroups)
						newGroups.delete(animal.grupo)
						return newGroups
					})
				} else {
					// Si estamos seleccionando
					// Primero, removemos cualquier otra selección del mismo grupo
					processedData.forEach((a, i) => {
						if (a.grupo === animal.grupo) {
							delete newSelection[i]
						}
					})
					// Luego agregamos la nueva selección
					newSelection[index] = true
					setSelectedGroups(prevGroups => {
						const newGroups = new Set(prevGroups)
						newGroups.add(animal.grupo)
						return newGroups
					})
				}

				return newSelection
			})
		} else {
			// Comportamiento normal para animales sin grupo
			setSelectedAnimals(prev => ({
				...prev,
				[index]: !prev[index]
			}))
		}
	}

	const isCheckboxDisabled = (animal, index) => {
		if (!animal.agregable) return true
		if (!animal.grupo) return false

		// Si el animal pertenece a un grupo y ya hay otro animal seleccionado de ese grupo
		return selectedGroups.has(animal.grupo) && !selectedAnimals[index]
	}

	const handleConfirmar = async () => {
		if (!processedData) return

		try {
			setLoading(true)
			const animalesSeleccionados = processedData.filter(
				(animal, index) => animal.agregable && selectedAnimals[index]
			)

			onSeleccionarAnimales(animalesSeleccionados)
			onClose()
		} catch (error) {
			setError('Error al procesar los animales')
		} finally {
			setLoading(false)
		}
	}

	return (
		<Modal show={true} onHide={onClose} size="xl" backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title className="d-flex align-items-center gap-2">
					<h4 className="m-0"><strong>Importar desde planilla</strong></h4>
					<InfoIcon
						text={
							<div>
								<p>El archivo debe contener:</p>
								<strong>Columnas obligatorias:<br /></strong> categoría, sexo<br />
								<strong>Columnas opcionales:</strong><br />
								DIOB<br />
								Núm. Caravana<br />
								Núm. Tatuaje<br />
								Carimbo<br />
								Raza<br />
								Color<br />
							</div>
						}
						placement="right"
					/>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col>
						<div className="mb-3">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										Instrucciones
									</Accordion.Header>
									<Accordion.Body>
										<Alert variant="info">
											Seleccionar el archivo con los animales a ser agregados al sistema.
											El archivo debe tener los títulos de las columnas en la primera fila
											y todos los animales a ser agregados desde la segunda fila, de manera consecutiva.
										</Alert>
										<div className="text-center mt-3">
											<img
												src={Ejemplo}
												alt="Ejemplo de formato Excel"
												className="img-fluid"
												style={{ maxWidth: '100%', border: '1px solid #dee2e6' }}
											/>
										</div>
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>

						{error && (
							<Alert variant="danger">
								{error}
							</Alert>
						)}


						<Form.Check
							id="checkIdentificacionesRepetidas"
							type="checkbox"
							label="Permitir identificaciones repetidas"
							checked={permitirRepetidos}
							onChange={handlePermitirRepetidosChange}
						/>
						<div className="mb-3">
							<FileUpload
								title="Archivo Excel"
								buttonText="Seleccionar"
								buttonTextSingular="archivo Excel"
								buttonIconClass="fas fa-file-excel"
								accept=".xlsx,.xls"
								multiple={false}
								updateFiles={handleFileUpdate}
								disabled={loading}
							/>
						</div>

						{loading && (
							<div className="text-center my-3">
								<Spinner animation="border" variant="primary" />
								<p className="mt-2">Procesando archivo...</p>
							</div>
						)}

						{stats && (
							<div className="d-flex gap-3 mb-3">
								<div className="badge bg-success">
									Agregables: {stats.totalAgregables}
								</div>
								<div className="badge bg-warning text-dark">
									No agregables: {stats.totalNoAgregables}
								</div>
							</div>
						)}

						{processedData && (
							<div>
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h5 className="m-0">Vista previa de los datos:</h5>
									<small className="text-muted">
										<i className="fas fa-mouse-pointer me-1"></i>
										Desplazar para ver más registros
									</small>
								</div>
								<div
									className="table-responsive"
									style={{
										maxHeight: '400px',
										overflowY: 'auto',
										border: '1px solid #dee2e6',
										borderRadius: '4px',
										boxShadow: 'inset 0 -10px 10px -10px rgba(0,0,0,0.1)'
									}}
								>
									<Table striped bordered hover size="sm" className="mb-0">
										<thead style={{
											position: 'sticky',
											top: 0,
											backgroundColor: 'white',
											zIndex: 1,
											boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
										}}>
											<tr>
												<th>Usar</th>
												<th>Categoría</th>
												<th>Sexo</th>
												<th>DIOB</th>
												<th>Núm. Caravana</th>
												<th>Núm. Tatuaje</th>
												<th>Carimbo</th>
												<th>Raza</th>
												<th>Color</th>
											</tr>
										</thead>
										<tbody>
											{processedData.map((animal, index) => (
												<tr key={index}>
													<td className="text-center">
														{animal.agregable ? (
															<div className="d-flex align-items-center gap-1" style={isCheckboxDisabled(animal, index) ? { cursor: 'help' } : {}}>
																<Form.Check
																	type="checkbox"
																	checked={selectedAnimals[index] || false}
																	onChange={() => handleCheckboxChange(index)}
																	disabled={isCheckboxDisabled(animal, index)}
																/>
																{isCheckboxDisabled(animal, index) && (
																	<InfoIcon
																		text={`Solo puede seleccionar un animal con esta combinación de identificadores: ${[
																			animal.numCaravana ? `Caravana ${animal.numCaravana}` : null,
																			animal.numChip ? `Chip ${animal.numChip}` : null,
																			animal.numTatuaje ? `Tatuaje ${animal.numTatuaje}` : null
																		].filter(Boolean).join(', ')
																			}`}
																		placement="right"
																	/>
																)}
															</div>
														) : (
															<InfoIcon text={animal.motivo} />
														)}
													</td>
													<td>{animal.categoria}</td>
													<td>{animal.sexo}</td>
													<td>{animal.numChip}</td>
													<td>{animal.numCaravana}</td>
													<td>{animal.numTatuaje}</td>
													<td>{animal.carimbo}</td>
													<td>{animal.raza}</td>
													<td>{animal.color}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</div>
							</div>
						)}
					</Col>
				</Row>
			</Modal.Body>

			<Modal.Footer>
				<Button
					variant="secondary"
					onClick={onClose}
					disabled={loading}
				>
					Cancelar
				</Button>
				<Button
					variant="primary"
					onClick={handleConfirmar}
					disabled={!processedData || loading}
				>
					{loading ? (
						<>
							<Spinner size="sm" className="me-2" />
							Importando...
						</>
					) : (
						'Importar'
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalImportarExcel