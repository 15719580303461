import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { CategoriaAnimalesSelects } from 'components/CategoriaAnimalesSelects'
import { InfoIcon } from 'components/InfoIcon'

export default function IngresoIndividual(props) {
	const { saveDisabled, onClose, onSave, cantidadAnimales, categoriasDisponibles } = props

	const [selectedIdentificadores, setSelectedIdentificadores] = useState({
		numCaravana: true,
		numChip: false,
		numTatuaje: false
	})

	const [autoIncrementar, setAutoIncrementar] = useState({
		numCaravana: true,
		numChip: true,
		numTatuaje: true
	})

	const [numAct, setNumAct] = useState(0)
	const [animales, setAnimales] = useState([{
		numCaravana: '',
		numChip: '',
		numTatuaje: '',
		carimbo: '',
		color: '',
		raza: '',
		categoriaId: '',
		sexo: '',
		pesoInicial: ''
	}])

	useEffect(() => {
		if (cantidadAnimales > 0) {
			if (cantidadAnimales - 1 > numAct) {
				const arr = []
				for (let i = 0; i < cantidadAnimales; i++) {
					let item = {}
					if (i < animales.length) {
						item = animales[i]
					} else {
						item = {
							numCaravana: '',
							numChip: '',
							numTatuaje: '',
							carimbo: '',
							color: '',
							raza: '',
							categoriaId: '',
							sexo: '',
							pesoInicial: ''
						}
					}
					arr.push(item)
				}
				setAnimales(arr)
			} else {
				const animalesCopy = []
				for (let i = 0; i < cantidadAnimales; i++) {
					animalesCopy.push(animales[i])
				}
				setNumAct(cantidadAnimales - 1)
				setAnimales(animalesCopy)
			}
		} else {
			setNumAct(0)
		}
	}, [cantidadAnimales])

	const handleChangeAnimal = (valor, cual, index = numAct) => {
		const animalesCopy = [...animales]
		animalesCopy[index][cual] = valor
		setAnimales(animalesCopy)
	}

	const onChangeCategoria = (categoriaData) => {
		const animalesCopy = [...animales]
		animalesCopy[numAct]['categoriaId'] = categoriaData.categoriaId
		animalesCopy[numAct]['sexo'] = categoriaData.sexo
		setAnimales(animalesCopy)
	}

	const verSiPuedeAvanzar = () => {
		const animalActual = animales[numAct]
		return Object.keys(selectedIdentificadores).some(id =>
			selectedIdentificadores[id] && animalActual[id]
		)
	}

	const avanzarNumAct = () => {
		const animalActual = animales[numAct]
		const siguienteAnimal = { ...animales[numAct + 1] }

		// Si ningún identificador está ingresado en el siguiente animal, inicializamos
		const siguienteSinIdentificadores = !siguienteAnimal.numCaravana &&
			!siguienteAnimal.numChip &&
			!siguienteAnimal.numTatuaje

		if (siguienteSinIdentificadores) {
			// Auto-incrementar identificadores seleccionados
			Object.keys(selectedIdentificadores).forEach(identificador => {
				if (selectedIdentificadores[identificador] &&
					autoIncrementar[identificador] &&
					Number(animalActual[identificador])) {
					siguienteAnimal[identificador] = String(Number(animalActual[identificador]) + 1);
				}
			})

			// Copiar otros campos
			siguienteAnimal.carimbo = animalActual.carimbo
			siguienteAnimal.color = animalActual.color
			siguienteAnimal.raza = animalActual.raza
			siguienteAnimal.categoriaId = animalActual.categoriaId
			siguienteAnimal.sexo = animalActual.sexo

			// Actualizar el siguiente animal
			const animalesCopy = [...animales]
			animalesCopy[numAct + 1] = siguienteAnimal
			setAnimales(animalesCopy)
		}

		setNumAct(prev => prev + 1)
	}

	const handleClose = () => {
		onClose()
	}

	const handleSave = () => {
		const animalesData = {
			conIdentificacion: false,
			animales: animales.map(animal => {
				// Filtrar solo los identificadores seleccionados
				const animalFiltrado = {};
				Object.keys(selectedIdentificadores).forEach(id => {
					if (selectedIdentificadores[id] && animal[id]) {
						animalFiltrado[id] = animal[id];
					}
				})
				
				return {
					...animalFiltrado,
					carimbo: animal.carimbo || null,
					color: animal.color || null,
					raza: animal.raza || null,
					categoriaId: animal.categoriaId,
					sexo: animal.sexo,
					pesoInicial: animal.pesoInicial || null
				}
			})
		}

		onSave(animalesData, 'individual')
	}

	return (
		!isNaN(cantidadAnimales) && cantidadAnimales > 0 ? (
			<>
				<Row className="pt-3">
					<Col>
						<h5>Configuración de identificadores <InfoIcon text="Seleccione qué identificadores desea utilizar" /></h5>
						<Form.Group className="mb-3">
							<Form.Check
								id="check-caravana"
								type="checkbox"
								label="Núm. Caravana"
								checked={selectedIdentificadores.numCaravana}
								onChange={() => setSelectedIdentificadores(prev => ({
									...prev,
									numCaravana: !prev.numCaravana
								}))}
								inline
							/>
							<Form.Check
								id="check-chip"
								type="checkbox"
								label="DIOB"
								checked={selectedIdentificadores.numChip}
								onChange={() => setSelectedIdentificadores(prev => ({
									...prev,
									numChip: !prev.numChip
								}))}
								inline
							/>
							<Form.Check
								id="check-tatuaje"
								type="checkbox"
								label="Núm. Tatuaje"
								checked={selectedIdentificadores.numTatuaje}
								onChange={() => setSelectedIdentificadores(prev => ({
									...prev,
									numTatuaje: !prev.numTatuaje
								}))}
								inline
							/>
						</Form.Group>

						<h5>Autoincrementar <InfoIcon text="Los identificadores seleccionados se incrementarán automáticamente al pasar al siguiente animal" /></h5>
						<Form.Group className="mb-3">
							{selectedIdentificadores.numCaravana && (
								<Form.Check
									id="auto-caravana"
									type="checkbox"
									label="Núm. Caravana"
									checked={autoIncrementar.numCaravana}
									onChange={() => setAutoIncrementar(prev => ({
										...prev,
										numCaravana: !prev.numCaravana
									}))}
									inline
								/>
							)}
							{selectedIdentificadores.numChip && (
								<Form.Check
									id="auto-chip"
									type="checkbox"
									label="DIOB"
									checked={autoIncrementar.numChip}
									onChange={() => setAutoIncrementar(prev => ({
										...prev,
										numChip: !prev.numChip
									}))}
									inline
								/>
							)}
							{selectedIdentificadores.numTatuaje && (
								<Form.Check
									id="auto-tatuaje"
									type="checkbox"
									label="Núm. Tatuaje"
									checked={autoIncrementar.numTatuaje}
									onChange={() => setAutoIncrementar(prev => ({
										...prev,
										numTatuaje: !prev.numTatuaje
									}))}
									inline
								/>
							)}
						</Form.Group>
					</Col>
				</Row>

				<Row className="mt-4">
					<Col>
						Animal {numAct + 1}/{cantidadAnimales}
					</Col>
				</Row>
				<Row>
					<Col sm="6" lg="4">
						<div className="border p-3">
							{selectedIdentificadores.numCaravana && (
								<Form.Group className="mb-3" controlId="preexistente-crear-individual-numCaravana">
									<Form.Label className="mb-1">Núm. Caravana</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numCaravana}
										onChange={(e) => handleChangeAnimal(e.target.value, 'numCaravana')}
									/>
								</Form.Group>
							)}
							{selectedIdentificadores.numChip && (
								<Form.Group className="mb-3" controlId="preexistente-crear-individual-numChip">
									<Form.Label className="mb-1">DIOB</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numChip}
										onChange={(e) => handleChangeAnimal(e.target.value, 'numChip')}
									/>
								</Form.Group>
							)}
							{selectedIdentificadores.numTatuaje && (
								<Form.Group className="mb-3" controlId="preexistente-crear-individual-numTatuaje">
									<Form.Label className="mb-1">Núm. Tatuaje</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numTatuaje}
										onChange={(e) => handleChangeAnimal(e.target.value, 'numTatuaje')}
									/>
								</Form.Group>
							)}
							<CategoriaAnimalesSelects
								opciones={categoriasDisponibles}
								initialCategoriaId={'1'}
								seleccionados={animales[numAct]}
								setSeleccionados={(param) => true}
								onSave={onChangeCategoria}
							/>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-carimbo">
								<Form.Label className="mb-1">Carimbo</Form.Label>
								<Form.Control
									size="sm"
									type="number"
									value={animales[numAct].carimbo}
									onChange={(e) => handleChangeAnimal(e.target.value, 'carimbo')}
								/>
							</Form.Group>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-color">
								<Form.Label className="mb-1">Color</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].color}
									onChange={(e) => handleChangeAnimal(e.target.value, 'color')}
								/>
							</Form.Group>
							<Form.Group className="mb-2" controlId="preexistente-crear-individual-raza">
								<Form.Label className="mb-1">Raza</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].raza}
									onChange={(e) => handleChangeAnimal(e.target.value, 'raza')}
								/>
							</Form.Group>
							<Form.Group className="mb-2 col-12 col-sm-4 mb-3" controlId="preexistente-crear-individual-pesoInicial">
								<Form.Label className="mb-1">Peso inicial</Form.Label>
								<Form.Control
									size="sm"
									value={animales[numAct].pesoInicial}
									type="number"
									onChange={(e) => handleChangeAnimal(e.target.value, 'pesoInicial')}
								/>
							</Form.Group>
						</div>
					</Col>
				</Row>
				<Row className="mt-4 mb-3">
					<Col sm="6" lg="4" className="d-flex justify-content-around">
						<Button
							variant="success"
							onClick={() => setNumAct(prev => prev - 1)}
							disabled={numAct === 0}
						>
							<i className="fa fa-caret-left"></i> ANTERIOR
						</Button>
						<Button
							variant="success"
							onClick={avanzarNumAct}
							disabled={numAct === cantidadAnimales - 1 || !verSiPuedeAvanzar()}
						>
							SIGUIENTE <i className="fa fa-caret-right"></i>
						</Button>
					</Col>
				</Row>
				<Row className="mt-4 mb-3">
					<Col className="d-flex justify-content-end">
						<Button
							variant="secondary"
							className="me-2"
							onClick={handleClose}
						>
							Cancelar
						</Button>
						<Button
							variant="primary"
							onClick={handleSave}
							disabled={saveDisabled || numAct !== cantidadAnimales - 1 || !verSiPuedeAvanzar()}
						>
							Ingresar animales
						</Button>
					</Col>
				</Row>
			</>
		) : (
			<Alert variant="warning">Se debe especificar el número de animales</Alert>
		)
	)
}