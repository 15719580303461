import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { InfoIcon } from 'components/InfoIcon'
import { ButtonWithTooltip } from 'components/ButtonWithTooltip'


export default function IdentificacionProcesar(props) {
	const { config, tarea, onClose, onProcesar, verFinalizarTarea, setCambiosData } = props

	const initAnimales = () => {
		let arr = []
		for (let i = 0; i < tarea.identificacion.cantidad; i++) {
			arr.push({
				numCaravana: '',
				numChip: '',
				numTatuaje: '',
				carimbo: '',
				color: '',
				raza: '',
			})
		}

		return arr
	}

	const [animales, setAnimales] = useState(initAnimales())
	const [numAct, setNumAct] = useState(0)
	const [autoIncrementarCaravana, setAutoIncrementarCaravana] = useState(true)
	const [autoIncrementarChip, setAutoIncrementarChip] = useState(true)
	const [autoIncrementarTatuaje, setAutoIncrementarTatuaje] = useState(true)

	const verSiCompletadoDatosAnimal = (datos) => {
		// se completa cuando se especifica al menos un campo de identificación
		return datos.numCaravana || datos.numChip || datos.numTatuaje
	}

	const verSiCompleto = () => {
		let completo = true

		animales.forEach(animal => {
			if (!verSiCompletadoDatosAnimal(animal)) {
				completo = false
			}
		})

		return completo
	}

	useEffect(() => {
		let numCompletados = 0
		animales.forEach(a => {
			if (verSiCompletadoDatosAnimal(a)) {
				numCompletados++
			}
		})

		if (numCompletados) {
			setCambiosData({
				nombre: tarea.nombre,
				texto: `${numCompletados} ${(numCompletados === 1 ? 'animal identificado' : 'animales identificados')}`
			})
		} else {
			setCambiosData(null)
		}
	}, [animales])

	const isCompleted = verSiCompleto()
	const siguienteDisabledText = verSiCompletadoDatosAnimal(animales[numAct]) ? '' : 'Se debe especificar un número de identificador para poder avanzar'

	const changeAnimalData = (valor, cual, indice = -1) => {
		const i = indice === -1 ? numAct : indice
		const animalesCopy = [...animales]
		animalesCopy[i][cual] = valor

		setAnimales(animalesCopy)
	}

	const changeAnimal = (animalData, indice) => {
		const animalesCopy = [...animales]
		animalesCopy[indice] = animalData

		setAnimales(animalesCopy)
	}

	const avanzarNumAct = () => {
		const animalData = { ...animales[numAct] }

		// Si ningún tipo de identificador está ingresado, se presupone que es la primrea vez que se visita el animal, por lo que se inicializa con los valores anteriores
		if (!animales[numAct + 1].numCaravana && !animales[numAct + 1].numChip && !animales[numAct + 1].numTatuaje) {
			// Si los tipos de identificador seleccionados como autoincrementar están en '', se inicializan en uno más que el animal anterior
			if (autoIncrementarCaravana && Number(animalData.numCaravana)) {
				animalData.numCaravana = Number(animalData.numCaravana) + 1
			}

			if (autoIncrementarChip && Number(animalData.numChip)) {
				animalData.numChip = Number(animalData.numChip) + 1
			}

			if (autoIncrementarTatuaje && Number(animalData.numTatuaje)) {
				animalData.numTatuaje = Number(animalData.numTatuaje) + 1
			}

			animalData.carimbo = animales[numAct].carimbo
			animalData.color = animales[numAct].color
			animalData.raza = animales[numAct].raza

			changeAnimal(animalData, numAct + 1)
		}

		// se avanza el animal
		setNumAct(prev => prev + 1)
	}

	const handleSave = () => {
		const serverData = {
			id: tarea.id,
			identificacion: {
				estado: 'procesado',
				animales: animales.map(animal => {
					const campos = ['numChip', 'numCaravana', 'numTatuaje', 'carimbo', 'color', 'raza']
					campos.forEach(campo => {
						if (!animal[campo]) {
							delete animal[campo]
						}
					})

					return animal
				})
			}
		}

		onClose(true)
		onProcesar(serverData)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Container>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col className="ms-2">
							<h5>{tarea.nombre}</h5>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Card className="mt-3">
				<Card.Body>
					<Row>
						<Col>
							<h5>Autoincrementar automáticamente <InfoIcon>Cuando se pasa al siguiente animal, se autoincrementan <br />los valores de los identificadores que estén marcados abajo</InfoIcon></h5>
							<Form.Group className="mb-3">
								<Form.Check
									id="check-chip"
									type="checkbox"
									label="DIOB"
									checked={autoIncrementarChip}
									onChange={(e) => setAutoIncrementarChip(!autoIncrementarChip)}
									inline
								/>
								<Form.Check
									id="check-caravana"
									type="checkbox"
									label="numCaravana"
									checked={autoIncrementarCaravana}
									onChange={(e) => setAutoIncrementarCaravana(!autoIncrementarCaravana)}
									inline
								/>
								<Form.Check
									id="chekc-tatuaje"
									type="checkbox"
									label="numTatuaje"
									checked={autoIncrementarTatuaje}
									onChange={(e) => setAutoIncrementarTatuaje(!autoIncrementarTatuaje)}
									inline
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col>
							{`Identificando animal ${numAct + 1}/${tarea.identificacion.cantidad}`}
						</Col>
					</Row>
					<Row>
						<Col sm="6" lg="4">
							<div className="border p-3">
								<Form.Group className="mb-3" controlId="numChip">
									<Form.Label className="mb-1">DIOB</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numChip}
										onChange={(e) => changeAnimalData(e.target.value, 'numChip')}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="numCaravana">
									<Form.Label className="mb-1">Núm. Caravana</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numCaravana}
										onChange={(e) => changeAnimalData(e.target.value, 'numCaravana')}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="numTatuaje">
									<Form.Label className="mb-1">Núm. Tatuaje</Form.Label>
									<Form.Control
										type="text"
										size="sm"
										value={animales[numAct].numTatuaje}
										onChange={(e) => changeAnimalData(e.target.value, 'numTatuaje')}
									/>
								</Form.Group>
								<Form.Group className="mb-2" controlId="carimbo">
									<Form.Label className="mb-1">Carimbo</Form.Label>
									<Form.Control
										size="sm"
										type="number"
										min={0}
										value={animales[numAct].carimbo}
										onChange={(e) => changeAnimalData(e.target.value, 'carimbo')}
									/>
								</Form.Group>
								<Form.Group className="mb-2" controlId="color">
									<Form.Label className="mb-1">Color</Form.Label>
									<Form.Control
										size="sm"
										value={animales[numAct].color}
										onChange={(e) => changeAnimalData(e.target.value, 'color')}
									/>
								</Form.Group>
								<Form.Group className="mb-2" controlId="raza">
									<Form.Label className="mb-1">Raza</Form.Label>
									<Form.Control
										size="sm"
										value={animales[numAct].raza}
										onChange={(e) => changeAnimalData(e.target.value, 'raza')}
									/>
								</Form.Group>
							</div>
						</Col>
					</Row>
					<Row className="mt-4 mb-3">
						<Col sm="6" lg="4" className="d-flex justify-content-around">
							<Button
								variant="outline-success"
								onClick={() => setNumAct(prev => prev - 1)}
								disabled={numAct === 0}
							>
								<i className="fa fa-caret-left"></i> Anterior
							</Button>
							<ButtonWithTooltip
								tooltipText={siguienteDisabledText}
								variant="outline-success"
								onClick={avanzarNumAct}
								disabled={numAct === tarea.identificacion.cantidad - 1 || siguienteDisabledText !== ''}
							>
								Siguiente <i className="fa fa-caret-right"></i>
							</ButtonWithTooltip>
						</Col>
					</Row>
				</Card.Body>
				<Card.Footer>
					<Row className="mt-4 mb-3">
						<Col className="d-flex justify-content-end">
							<Button
								variant="secondary"
								className="me-2"
								onClick={handleClose}
							>
								Cancelar
							</Button>
							<Button
								variant="primary"
								onClick={handleSave}
								disabled={!isCompleted}
							>
								Procesar tarea
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>
		</Container>
	)
}