import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

export default function Establecimiento(props) {
	const { establecimiento, onSave, onCancel } = props

	const [nombre, setNombre] = useState(establecimiento?.nombre || '')
	const [codigo, setCodigo] = useState(establecimiento?.codigo || '')
	const [partes, setPartes] = useState(establecimiento.partes ? establecimiento.partes.map(parte => ({...parte})) : [])
	const [partesEliminadasIds, setPartesEliminadasIds] = useState([])

	const handleChangeNombre = (event) => {
		setNombre(event.target.value)
	}

	const handleChangeCodigo = (event) => {
		setCodigo(event.target.value)
	}

	const handleCreateParte = () => {
		const newArr = [...partes]
		newArr.push({ nombre: '' })
		setPartes(newArr)
	};

	const handleChangeParte = (event, i) => {
		const newArr = [...partes]
		newArr[i].nombre = event.target.value
		if (newArr[i].id) {
			newArr[i].modificada = true
		}
		setPartes(newArr)
	}

	const handleDeleteParte = (i) => {
		let newArr = [...partes]
		const eliminado = newArr.splice(i, 1)
		setPartes(newArr)

		if (eliminado[0].id) {
			setPartesEliminadasIds(prev => [...prev, eliminado[0].id])
		}
	}

	const isValid = () => {
		let valid = false
		let partesVacias = false

		if (nombre !== '' && codigo !== '') {
			valid = true
		}

		partes.forEach(parte => {
			if (parte.nombre === '') {
				partesVacias = true
			}
		})

		if (!valid) {
			toast.warn(`Completar todos los campos antes de guardar.`)
		} else if(!partes.length || partesVacias) {
			toast.warn(`Debe haber al menos un sector y los sectores deben tener un nombre.`)
		}
		
		return valid && partes.length && !partesVacias
	}

	const handleClose = () => {
		onCancel()
	}

	const handleSave = () => {
		if (isValid()) {
			const nuevoEstablecimiento = {
				nombre,
				codigo,
			}

			if (establecimiento?.id) {
				nuevoEstablecimiento.id = establecimiento.id
			}

			const nuevasPartes = []
			const editarPartes = []
			partes.forEach(parte => {
				if (!parte.id) {
					nuevasPartes.push(parte)
				} else if (parte.modificada) {
					editarPartes.push(parte)
				}
			})

			if (nuevasPartes.length) {
				nuevoEstablecimiento.nuevasPartes = nuevasPartes
			}

			if (editarPartes.length) {
				nuevoEstablecimiento.editarPartes = editarPartes
			}

			if (partesEliminadasIds.length) {
				nuevoEstablecimiento.eliminarPartesIds = partesEliminadasIds
			}

			onSave(nuevoEstablecimiento)
		}
	}

	return (
		<Modal show={true} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>{establecimiento.id ? 'Editar' : 'Nuevo'} Establecimiento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col>
							<Form.Group id="nombre">
								<Form.Label>Nombre</Form.Label>
								<Form.Control
									placeholder="Nombre"
									type="text"
									value={nombre}
									onChange={handleChangeNombre}
									required
								/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group id="codigo">
								<Form.Label>Código</Form.Label>
								<Form.Control
									placeholder="Código"
									type="text"
									value={codigo}
									onChange={handleChangeCodigo}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								className="my-3"
								onClick={() => handleCreateParte()}
								variant="outline-primary"
							>
								Agregar Sector <i className="fa-solid fa-plus"></i>
							</Button>
							<h5 className="text-muted">Sectores:</h5>
						</Col>
					</Row>
					{partes.map((parte, i) => {
						return (
							<Row key={`parte-${i}`} className="mt-2">
								<Col>
									<Form.Group id={`parte-${i}`}>
										<Form.Control
											value={parte.nombre}
											type="text"
											required
											onChange={(e) => handleChangeParte(e, i)}
										/>
									</Form.Group>
								</Col>
								<Col>
									<Button
										onClick={() => handleDeleteParte(i)}
										variant="danger"
									>
										<i className="fa-solid fa-trash-can"></i>
									</Button>
								</Col>
							</Row>
						)
					})}
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancelar
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Guardar
				</Button>
			</Modal.Footer>
		</Modal>
	)
}