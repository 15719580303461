import React, { useEffect } from 'react';

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import MenuSeparator from './MenuSeparator';
import { getData } from 'utils/localstorage'

import { removeItem } from 'utils/localstorage'
import { LOCALSTORAGE_KEY } from 'utils/constants'
import Logo from 'assets/logo2.png'
import './SideMenu.css'

function SideMenu(props) {
	const { location, history, setLoggedIn, menuExpanded, setMenuExpanded } = props

	const cuenta = getData('cuenta') ?? {}
	const usuario = getData('usuario') ?? {}

	useEffect(() => {

	}, [cuenta, usuario])

	const onToggle = (isExpanded) => {
		setMenuExpanded(isExpanded)
	}

	const handleLogOut = () => {
		setLoggedIn(false);
		removeItem(LOCALSTORAGE_KEY);
	}

	let selected = location.pathname.substring(1)
	if (selected === 'login' || selected === '') {
		selected = 'inicio'
	}

	return (
		<SideNav expanded={menuExpanded} onToggle={onToggle} id="sidenav"
			onSelect={(selected) => {
				const to = '/' + selected;
				if (location.pathname !== to) {
					history.push(to);
				}
			}}
		>
			<SideNav.Toggle />
			<div className="sidenavHeader">

			</div>
			<SideNav.Nav defaultSelected={selected}>
				<div className="sidenavLogo">
					<img src={Logo} alt="logo" />
				</div>

				{/* Account info section - custom implementation */}
				<div className="account-container">
					{/* Icon visible only when collapsed */}
					<div className="account-icon">
						<i className="fa fa-user-circle" />
					</div>

					{/* Info visible only when expanded */}
					<div className="sidenavInfo">
						<div className="accountName">{cuenta?.razonSocial}</div>
						<div className="accountDetails">{cuenta?.ruc}</div>
						<div className="userInfo">{usuario?.user}</div>
					</div>
				</div>

				<NavItem eventKey="inicio">
					<NavIcon>
						<i className="fa fa-fw fa-home" />
					</NavIcon>
					<NavText>
						Inicio
					</NavText>
				</NavItem>
				<NavItem eventKey="configuraciones">
					<NavIcon>
						<i className="fa fa-cog" />
					</NavIcon>
					<NavText>
						Configuraciones <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="configuraciones/establecimientos">
						<NavText title="Establecimientos">
							Establecimientos
						</NavText>
					</NavItem>
					<NavItem eventKey="configuraciones/propietarios">
						<NavText title="Propietarios">
							Propietarios
						</NavText>
					</NavItem>
					<NavItem eventKey="configuraciones/lotes">
						<NavText title="Lotes">
							Lotes
						</NavText>
					</NavItem>
				</NavItem>
				<NavItem eventKey="stock">
					<NavIcon>
						<i className="fa fa-cow" />
					</NavIcon>
					<NavText>
						Stock
					</NavText>
				</NavItem>
				<NavItem eventKey="busqueda">
					<NavIcon>
						<i className="fa fa-magnifying-glass" />
					</NavIcon>
					<NavText>
						Búsqueda
					</NavText>
				</NavItem>
				<NavItem eventKey="tareas">
					<NavIcon>
						<i className="fa fa-list-check" />
					</NavIcon>
					<NavText>
						Tareas
					</NavText>
				</NavItem>
				<NavItem eventKey="clima">
					<NavIcon>
						<i className="fa fa-cloud-rain" />
					</NavIcon>
					<NavText>
						Clima
					</NavText>
				</NavItem>
				<NavItem eventKey="reportes">
					<NavIcon>
						<i className="fa fa-chart-simple" />
					</NavIcon>
					<NavText>
						Reportes <div className="submenu-nav"><i className="fa fa-caret-down expand"></i> <i className="fa fa-caret-up collapse"></i></div>
					</NavText>
					<NavItem eventKey="reportes/entradas">
						<NavText title="Entradas">
							Entradas
						</NavText>
					</NavItem>
					<NavItem eventKey="reportes/salidas">
						<NavText title="Salidas">
							Salidas
						</NavText>
					</NavItem>
					<NavItem eventKey="reportes/pesajes">
						<NavText title="Pesajes">
							Pesajes
						</NavText>
					</NavItem>
					<NavItem eventKey="reportes/pariciones">
						<NavText title="Pariciones">
							Pariciones
						</NavText>
					</NavItem>
				</NavItem>
				<MenuSeparator />
				<NavItem eventKey="logout" onClick={handleLogOut}>
					<NavIcon>
						<i className="fa fa-fw fa-power-off" style={{ fontSize: '1.5em' }} />
					</NavIcon>
					<NavText style={{ paddingRight: 32 }} title="Cerrar">
						Cerrar
					</NavText>
				</NavItem>
			</SideNav.Nav>
		</SideNav>
	)
}

export default SideMenu