import React from 'react'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'


import { TIPOS_IDENTIFICACION } from 'config/generales'

export default function SelectorAnimal(props) {
	const { tipoIdentificacion, setTipoIdentificacion, numIdentificacion, changeNumIdentificacion, inline = false } = props

	const tiposIdentificacion = TIPOS_IDENTIFICACION

	return (
		<Row className="my-3">
			<Form.Group controlId="selectorAnimal-tipoIdentificacion" className={`mb-1 ${inline ? "col" : "col-12 col-sm-6 col-lg-5"}`}>
				<Form.Label className="mb-1">Tipo identificación</Form.Label>
				<Form.Select
					size="sm"
					value={tipoIdentificacion}
					onChange={(e) => setTipoIdentificacion(e.target.value)}
				>
					{tiposIdentificacion.map((opcion) => {
						const mapValue = {
							DIOB: 'numChip',
							Caravana: 'numCaravana',
							Tatuaje: 'numTatuaje'
						}
						return (
							<option value={mapValue[opcion]} key={`selectorAnimal-tipoIdentificacion-${opcion}`}>
								{opcion}
							</option>
						)
					})}
				</Form.Select>
			</Form.Group>
			<Form.Group className={`${inline ? "col" : "col-12 col-sm-6 col-lg-5"}`} controlId="selectorAnimal-numIdentificador">
				<Form.Label className="mb-1">Núm. Identificador</Form.Label>
				<Form.Control
					type="text"
					size="sm"
					value={numIdentificacion}
					onChange={(e) => changeNumIdentificacion(e.target.value)}
					autoFocus
				/>
			</Form.Group>
		</Row>
	)
}