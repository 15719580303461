import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import TopHeader from 'components/TopHeader'
import axios from 'axios'
import api from 'api/api'

// Importación de componentes
import DashboardHeader from './DashboardHeader'
import StockCard from './StockCard'
import VentasCard from './VentasCard'
import ReproduccionCard from './ReproduccionCard'
import ClimaCard from './ClimaCard'
import TareasCard from './TareasCard'

// Importación de utilidades
import { calcularTotales } from './dataCalculations'

import './home.css'

function Home(props) {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [establecimientos, setEstablecimientos] = useState([])
	const [selectedEstablecimientoId, setSelectedEstablecimientoId] = useState('total')
	const [data, setData] = useState({
		total: {
			animales: {
				stock: [],
				reproduccion: {
					servidas: { anhoActual: 0, anhoPasado: 0 },
					prenadas: { anhoActual: 0, anhoPasado: 0 },
					nacidos: { anhoActual: 0, anhoPasado: 0 }
				},
				ventas: []
			},
			clima: {
				mesCorriente: {
					nombre: '',
					actual: 0,
					anhoPasado: 0,
					promedio: 0,
					min: 0,
					max: 0
				},
				acumuladoAnho: 0,
				ultimos12Meses: {
					total: 0,
					periodo: ''
				}
			}
		},
		tareas: []
	})

	// Configuración del token de cancelación
	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	// Carga de datos cuando el cancelToken está listo
	useEffect(() => {
		if (cancelToken) {
			refreshData(cancelToken)
		}
	}, [cancelToken])

	// Función para obtener los datos
	const refreshData = async (cancelToken) => {
		setLoading(true);
		try {
			const { datos: respuesta, error } = await api.get('u/dashboard', { cancelToken: cancelToken.token });

			if (!error && respuesta) {
				try {
					// Crear una copia segura con estructura garantizada
					const datosModificados = { ...respuesta };

					// Garantizar que existe data.total con la estructura correcta
					if (!datosModificados.total || !datosModificados.total.animales || !Array.isArray(datosModificados.total.animales.stock)) {
						datosModificados.total = calcularTotales(datosModificados);
					}

					// Extraer establecimientos de forma segura
					const establecimientosArray = [];

					for (const [id, datos] of Object.entries(datosModificados)) {
						if (id !== 'total' && id !== 'tareas' && datos && typeof datos === 'object' && datos.nombre) {
							establecimientosArray.push({
								id,
								nombre: datos.nombre
							});
						}
					}

					setEstablecimientos(establecimientosArray);
					setData(datosModificados);
				} catch (e) {
					console.error("Error procesando datos:", e);
					// Mantener el estado anterior en caso de error
				}
			}
		} catch (error) {
			if (!axios.isCancel(error)) {
				console.error('fetchData error', error);
				setError(error);
			}
		} finally {
			setLoading(false);
		}
	}

	// Función para cambiar el establecimiento seleccionado
	const handleEstablecimientoChange = (e) => {
		setSelectedEstablecimientoId(e.target.value)
	}

	// Obtener los datos del establecimiento seleccionado o los totales
	const getSelectedData = () => {
		if (selectedEstablecimientoId === 'total') {
			// Asegurarse de que data.total exista y tenga estructura para stock
			if (!data.total || !data.total.animales || !data.total.animales.stock) {
				const totalCalculado = calcularTotales(data)

				data.total = totalCalculado
			}
			return data.total || {}
		} else {
			return data[selectedEstablecimientoId] || {}
		}
	}

	const selectedData = getSelectedData()
	const isTotalView = selectedEstablecimientoId === 'total'

	// Renderizar el contenido cuando está cargando
	if (loading) {
		return (
			<Container fluid id="home" className="vh-100">
				<TopHeader
					iconName="home"
					text="Inicio"
				/>
				<div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 100px)' }}>
					<div className="text-center">
						<Spinner animation="border" variant="success" style={{ width: '3rem', height: '3rem' }} />
						<p className="mt-3">Cargando datos...</p>
					</div>
				</div>
			</Container>
		)
	}

	return (
		<Container fluid id="home" className="">
			<TopHeader
				iconName="home"
				text="Inicio"
			/>

			{/* Cabecera del Dashboard */}
			<DashboardHeader
				loading={loading}
				refreshData={() => cancelToken && refreshData(cancelToken)}
				selectedEstablecimientoId={selectedEstablecimientoId}
				establecimientos={establecimientos}
				onEstablecimientoChange={handleEstablecimientoChange}
			/>

			<Container className="dashboard-container py-3">
				{/* Primera fila: Stock y Ventas/Reproducción */}
				<Row className="mb-4">
					{/* Gráfico de Stock */}
					<Col lg={6} className="mb-4 mb-lg-0">
						<StockCard
							stockData={selectedData?.animales?.stock || []}
							nombreEstablecimiento={!isTotalView && selectedData.nombre ? selectedData.nombre : null}
						/>
					</Col>

					{/* Columna de Ventas y Reproducción */}
					<Col lg={6}>
						{/* Tabla de Ventas */}
						<VentasCard
							ventasData={selectedData?.animales?.ventas || []}
							nombreEstablecimiento={!isTotalView ? selectedData.nombre : null}
						/>

						{/* Reproducción */}
						<ReproduccionCard
							reproduccionData={selectedData?.animales?.reproduccion || {}}
							nombreEstablecimiento={!isTotalView ? selectedData.nombre : null}
						/>
					</Col>
				</Row>

				{/* Segunda fila: Clima (solo si no es vista total) */}
				{!isTotalView && (
					<Row className="mb-4">
						<Col md={12}>
							<ClimaCard
								climaData={selectedData?.clima || {}}
								nombreEstablecimiento={selectedData.nombre}
							/>
						</Col>
					</Row>
				)}

				{/* Tercera fila: Próximas tareas */}
				<Row>
					<Col md={12}>
						<TareasCard tareasData={data.tareas || []} />
					</Col>
				</Row>
			</Container>
		</Container>
	)
}

export default Home