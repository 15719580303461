import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'

import './busqueda.css'
import SelectorAnimal from 'pages/tareas/SelectorAnimal'
import { debounce } from 'utils/debounce'
import { FichaAnimal } from 'components/FichaAnimal'
import TopHeader from 'components/TopHeader'

function Busqueda(props) {
	const [loading, setLoading] = useState(false)
	const [establecimientos, setEstablecimientos] = useState([])
	const [propietarios, setPropietarios] = useState([])
	const [tipoIdentificacion, setTipoIdentificacion] = useState('numCaravana')
	const [numIdentificacion, setNumIdentificacion] = useState('')
	const [busquedaIdentificacion, setBusquedaIdentificacion] = useState('')
	const [animales, setAnimales] = useState([])
	const [animal, setAnimal] = useState(null)

	const changeNumIdentificacion = (numId) => {
		setBusquedaIdentificacion(numId)
	}

	const debouceBusquedaIdentificacion = React.useCallback(
		debounce(setNumIdentificacion, 400),
		[]
	)

	useEffect(() => {
		refreshConfig()
	}, [])

	useEffect(() => {
		if (!loading && numIdentificacion !== busquedaIdentificacion) {
			debouceBusquedaIdentificacion(busquedaIdentificacion)
		}
	}, [busquedaIdentificacion])

	useEffect(() => {
		if (numIdentificacion.trim() !== '') {
			fetchAnimal(numIdentificacion)
		}
	}, [numIdentificacion])

	const refreshConfig = async () => {
		try {
			const { datos: respuesta } = await api.get('u/config-stock')

			setEstablecimientos(respuesta.establecimientos)
			setPropietarios(respuesta.propietarios)
		} catch (error) {
			console.log('ERROR:', error)
		} finally {
			setLoading(false)
		}
	}

	const fetchAnimal = async (numId) => {
		try {
			setLoading(true)
			const { datos: respuesta } = await api.get(`u/animales/${tipoIdentificacion}/${numId}`)

			if (respuesta.length === 0) {
				setAnimal(null)
				setAnimales([])
			} else if (respuesta.length === 1) {
				setAnimales(respuesta)
				setAnimal(respuesta[0])
			} else {
				setAnimales(respuesta)
				setAnimal(null)
			}
		} catch (error) {
			console.log('ERROR', error)
		} finally {
			setLoading(false)
		}
	}

	const onSave = (datos) => {

	}

	const onCloseFicha = () => {
		setAnimal(null)
		setBusquedaIdentificacion('')
	}

	const mapLabelIdentificacion = {
		numChip: 'DIOB',
		numCaravana: 'caravana',
		numTatuaje: 'tatuaje'
	}

	// render
	return (
		<Container fluid id="home" className="vh-100">
			<TopHeader
				iconName="magnifying-glass"
				text="Búsqueda"
			/>
			<Row>
				<Col>
					<Card className="mt-3">
						<Card.Body>
							{loading ? (
								<LoadingSpinner />
							) : null}
							{numIdentificacion.trim() === '' ? (
								<Alert variant="info">Ingresar identificador para buscar animal</Alert>
							) : (
								animales.length > 1 ? (
									<div>
										<Card.Title>Más de un animal encontrado</Card.Title>
										<Row>
											<Col>
												<Table>
													<thead>
														<tr>
															<th></th>
															<th>DIOB</th>
															<th>Núm Caravana</th>
															<th>Núm Tatuaje</th>
															<th>Carimbo</th>
															<th>Raza</th>
															<th>Categoría</th>
															<th>Sexo</th>
														</tr>
													</thead>
													<tbody>
														{animales.map(a => {

															return (
																<tr key={a.id}>
																	<td>
																		<Form.Check
																			className="ms-2"
																			inline
																			name="animales"
																			type="radio"
																			value={a.id}
																			checked={animal?.id === a.id}
																			onChange={() =>
																				setAnimal(a)
																			}
																		/>
																	</td>
																	<td>
																		{a.numChip ?? '-'}
																	</td>
																	<td>
																		{a.numCaravana ?? '-'}
																	</td>
																	<td>
																		{a.numTatuaje ?? '-'}
																	</td>
																	<td>
																		{a.carimbo}
																	</td>
																	<td>
																		{a.raza}
																	</td>
																	<td>
																		{a.categoria.nombre}
																	</td>
																	<td>
																		{a.sexo}
																	</td>
																</tr>
															)
														})}
													</tbody>
												</Table>
											</Col>
										</Row>
									</div>
								) : animales.length === 1 && animal ? (
									<Alert variant="info">¡Animal encontrado! {!animal.enStock ? <><br /><strong><i className="fa fa-exclamation-triangle"></i> Animal fuera de stock</strong></> : ''}</Alert>
								) : (
									<Alert variant="warning">No se encontró un animal con <strong>{mapLabelIdentificacion[tipoIdentificacion]}</strong> "{numIdentificacion}"</Alert>
								)
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col lg={4}>
					<SelectorAnimal
						tipoIdentificacion={tipoIdentificacion}
						setTipoIdentificacion={setTipoIdentificacion}
						numIdentificacion={busquedaIdentificacion}
						changeNumIdentificacion={changeNumIdentificacion}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{animal && (
						<FichaAnimal
							key={animal.id}
							animal={animal}
							onSave={onSave}
							onCancel={onCloseFicha}
							propietarios={propietarios}
							establecimientos={establecimientos}
						/>
					)}
				</Col>
			</Row>
		</Container>
	);
}

export default Busqueda