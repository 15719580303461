import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { numberWithSeparator } from 'utils/numberWithSeparator'

// Función auxiliar para convertir números a string de manera segura
const safeNumberToString = (value) => {
	if (value === undefined || value === null) return '0'
	return value.toString()
}

const StockCard = ({ stockData, nombreEstablecimiento }) => {
	// Verificar que stockData sea un array
	const stockDataArray = Array.isArray(stockData) ? stockData : []

	// Filtrar el stock para mostrar solo categorías con al menos 1 animal
	const filteredStockData = stockDataArray.filter(item => item && (item.total || 0) > 0)

	// Calcular el total de animales
	const totalAnimals = filteredStockData.reduce((sum, item) => sum + (item?.total || 0), 0)

	return (
		<Card className="h-100 shadow-sm">
			<Card.Header className="bg-primary text-white">
				<h5 className="mb-0">
					Stock Actual
					{nombreEstablecimiento && (
						<small className="ms-2">({nombreEstablecimiento})</small>
					)}
				</h5>
			</Card.Header>
			<Card.Body>
				{!filteredStockData || filteredStockData.length === 0 ? (
					<div className="alert alert-info">
						No hay datos de stock disponibles para este establecimiento.
						<details className="mt-2 small">
							<summary>Información de depuración</summary>
							<pre className="mt-2 bg-light p-2" style={{ fontSize: '11px' }}>
								{JSON.stringify({ stockData: stockData || 'no data', filteredLength: filteredStockData?.length || 0 }, null, 2)}
							</pre>
						</details>
					</div>
				) : (
					<Row>
						<Col md={6}>
							<ResponsiveContainer width="100%" height={300}>
								<PieChart>
									<Pie
										data={filteredStockData}
										cx="50%"
										cy="50%"
										labelLine={false}
										outerRadius={100}
										fill="#8884d8"
										dataKey="total"
									>
										{filteredStockData.map((entry, index) => (
											<Cell key={`cell-${index}`} fill={entry.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`} />
										))}
									</Pie>
									<Tooltip
										formatter={(value) => [`${numberWithSeparator(safeNumberToString(value))} cabezas`, null]}
										labelFormatter={(index) => filteredStockData[index]?.nombre || 'Desconocido'}
									/>
								</PieChart>
							</ResponsiveContainer>
						</Col>
						<Col md={6}>
							<div className="stock-legend mb-3 px-1">
								<table className="table table-sm table-borderless">
									<thead>
										<tr>
											<th>Categoría</th>
											<th className="text-end">Total</th>
										</tr>
									</thead>
									<tbody>
										{filteredStockData.map((item, index) => (
											<tr key={index}>
												<td>
													<span
														className="color-dot me-2"
														style={{ backgroundColor: item.color || `#${Math.floor(Math.random() * 16777215).toString(16)}` }}
													></span>
													{item.nombre || 'Desconocido'}
												</td>
												<td className="text-end">{numberWithSeparator(safeNumberToString(item.total))}</td>
											</tr>
										))}
									</tbody>
									<tfoot>
										<tr className="fw-bold">
											<td>Total</td>
											<td className="text-end">{numberWithSeparator(safeNumberToString(totalAnimals))}</td>
										</tr>
									</tfoot>
								</table>
							</div>
						</Col>
					</Row>
				)}
			</Card.Body>
		</Card>
	)
}

export default StockCard