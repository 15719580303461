export const FETCH_DE_A_CUANTOS = 10
export const TIPOS_TAREAS = [
	{
		label: 'Ingreso preexistente',
		value: 'carga-preexistente'
	},
	{
		label: 'Adquisición',
		value: 'adquisicion'
	},
	{
		label: 'Pesaje',
		value: 'pesaje'
	},
	{
		label: 'Salida',
		value: 'salida'
	},
	{
		label: 'Servicio',
		value: 'servicio'
	},
	{
		label: 'Diagnóstico de preñez',
		value: 'diagnostico-prenez'
	},
	{
		label: 'Identificación',
		value: 'identificacion'
	},
	{
		label: 'Sanitación',
		value: 'sanitacion'
	},
]

export const TIPOS_EVENTOS = [
	{
		label: 'Mortandad',
		value: 'mortandad'
	},
	{
		label: 'Consumo',
		value: 'consumo'
	},
	{
		label: 'Parición',
		value: 'paricion'
	},
	{
		label: 'Cambio de categoría',
		value: 'cambio-categoria'
	},
]

export const TIPOS_A_LABEL_MAP = {
	'carga-preexistente': 'Preexistente',
	'adquisicion': 'Adquisición',
	'pesaje': 'Pesaje',
	'salida': 'Salida',
	'servicio': 'Servicio',
	'diagnostico-prenez': 'Diagnóstico de preñez',
	'identificacion': 'Identificación',
	'sanitacion': 'Sanitación',
	'mortandad': 'Mortandad',
	'consumo': 'Consumo',
	'paricion': 'Parición',
	'cambio-categoria': 'Cambio de categoría'
}