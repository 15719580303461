import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Datetime from "react-datetime"
import { handleChangeFecha } from 'utils/date'

export default function BaseDataInputs(props) {
	const { initialData, usuarios, saveData, vertical, numObservacionRows = false } = props

	const [data, setData] = useState({
		fechaParaCuando: initialData?.fechaParaCuando ?? '',
		asignadoId: initialData?.asignadoId ?? '',
		nombre: initialData?.nombre ?? '',
		observacion: initialData?.observacion ?? ''
	})

	const opcionesAsignado = [
		{id: null, label: '-'},
		...usuarios.map(usuario => ({
			id: usuario.id,
			label: `${usuario.nombre} ${usuario.apellido}`
		}))
	]

	const handleChange = (valor, cual) => {
		const nuevosDatos = {
			...data,
			[cual]: valor
		}

		setData(prev => nuevosDatos)
	}

	const handleSaveDatos = () => {
		const nuevosDatos = {...data}

		if (nuevosDatos.asignadoId === '' || nuevosDatos.asignadoId === '-') {
			nuevosDatos.asignadoId = null
		}

		saveData(nuevosDatos)
	}

	useEffect(() => {
		handleSaveDatos()
	}, [data])

	return (
		<Card className="card-bg-gray">
			<Card.Header as='h5'>Asignación</Card.Header>
			<Card.Body>
				<Row className="tareas-base-inputs flex align-items-center justify-content-between my-2">
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="txtTareaNombre" className="mb-3">
							<Form.Label className="mb-1">Nombre de la tarea</Form.Label>
							<Form.Control
								size="sm"
								value={data.nombre}
								onChange={(e) => handleChange(e.target.value, 'nombre')}
							/>
						</Form.Group>
					</Col>
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="txtTareaFechaParaCuando" className="mb-3">
							<Form.Label className="mb-1">Fecha a realizar</Form.Label>
							<Datetime
								dateFormat="YYYY-MM-DD"
								timeFormat={false}
								closeOnSelect={true}
								strictParsing={false}
								inputProps={{ placeholder: "YYYY-MM-DD", id: 'txtTareaFechaParaCuando', className: 'form-control form-control-sm' }}
								value={data.fechaParaCuando}
								onChange={(date) => handleChangeFecha(date, handleChange, 'fechaParaCuando')}
							/>
						</Form.Group>
					</Col>
					<Col sm={vertical ? "12" : "4"}>
						<Form.Group controlId="selTareaAsignadoId" className="mb-3">
							<Form.Label className="mb-1">Asignar a</Form.Label>
							<Form.Select
								size="sm"
								value={data.asignadoId}
								onChange={(e) => handleChange(e.target.value, 'asignadoId')}
							>
								{opcionesAsignado.map((usuario, i) => {
									return (
										<option value={usuario.id} key={`selectAsignado-${usuario.id}`}>
											{usuario.label}
										</option>
									)
								})}
							</Form.Select>
						</Form.Group>
					</Col>
					<Col sm="12">
						<Form.Group controlId="txtTareaObservacion" className="mb-0">
							<Form.Label>Observaciones</Form.Label>
							<Form.Control as="textarea" rows={numObservacionRows || 3}
								value={data.observacion}
								onChange={(e) => handleChange(e.target.value, 'observacion')}
							/>
						</Form.Group>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}