import React from 'react'
import { Container, Row, Col, Card, Form, Spinner } from 'react-bootstrap'

const DashboardHeader = ({
	loading,
	refreshData,
	selectedEstablecimientoId,
	establecimientos,
	onEstablecimientoChange
}) => {
	return (
		<Container className="mt-3">
			<Card className="shadow-sm border-0 mb-4">
				<Card.Body className="py-3">
					<Row className="align-items-center">
						<Col lg={7} md={12} className="mb-3 mb-lg-0">
							<div className="d-flex align-items-center">
								<div className="flex-shrink-0 me-3">
									<i className="fa fa-sync-alt text-success fa-lg"></i>
								</div>
								<div className="flex-grow-1">
									<h6 className="mb-1">Datos actualizados</h6>
									<p className="text-muted mb-0 small">{new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}</p>
								</div>
								<div className="flex-shrink-0">
									<button
										type="button"
										className="btn btn-sm btn-outline-success"
										onClick={refreshData}
										disabled={loading}
									>
										{loading ? (
											<>
												<Spinner animation="border" size="sm" className="me-1" />
												Actualizando...
											</>
										) : (
											<>
												<i className="fa fa-sync-alt me-1"></i> Actualizar
											</>
										)}
									</button>
								</div>
							</div>
						</Col>
						<Col lg={5} md={12}>
							<div className="d-flex align-items-center">
								<div className="flex-shrink-0 me-3">
									<i className="fa fa-map-marker-alt text-primary fa-lg"></i>
								</div>
								<div className="flex-grow-1">
									<h6 className="mb-1">Establecimiento</h6>
									<Form.Select
										value={selectedEstablecimientoId}
										onChange={onEstablecimientoChange}
										className="form-select-sm border-0 bg-light"
									>
										<option value="total">Todos los establecimientos</option>
										{establecimientos.map(est => (
											<option key={est.id} value={est.id}>{est.nombre}</option>
										))}
									</Form.Select>
								</div>
							</div>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	)
}

export default DashboardHeader