import React from 'react'
import { Card } from 'react-bootstrap'
import { getShortDisplayDate } from 'utils/date'
import { TIPOS_A_LABEL_MAP } from 'config/tareas'

const TareasCard = ({ tareasData }) => {
	return (
		<Card className="shadow-sm">
			<Card.Header className="bg-primary text-light d-flex justify-content-between align-items-center">
				<h5 className="mb-0">Próximas Tareas</h5>
				<a href="/tareas" className="btn btn-sm btn-outline-light">
					<i className="fa fa-list me-1"></i> Ver más
				</a>
			</Card.Header>

			<Card.Body>
				{!tareasData || tareasData.length === 0 ? (
					<div className="alert alert-info mb-0">
						<i className="fa fa-info-circle me-2"></i>
						No hay tareas programadas.
					</div>
				) : (
					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>Nombre</th>
									<th>Tipo</th>
									<th className="text-center"># Animales</th>
									<th>Fecha</th>
								</tr>
							</thead>
							<tbody>
								{tareasData.map((tarea, index) => (
									<tr key={tarea.id || index}>
										<td>{tarea.nombre || 'Sin nombre'}</td>
										<td>{TIPOS_A_LABEL_MAP[tarea.tipo] || tarea.tipo || 'Desconocido'}</td>
										<td className="px-3">
											<div className="d-flex align-items-center">
												<div className="progress me-2" style={{ height: '6px', width: '120px', flexShrink: 0 }}>
													<div
														className="progress-bar"
														role="progressbar"
														style={{
															width: `${Math.min(100, ((tarea.animalesProcesados || 0) / Math.max(1, tarea.animalesDeseados || 1)) * 100)}%`,
															backgroundColor: (tarea.animalesProcesados || 0) >= (tarea.animalesDeseados || 0) ? '#28a745' : '#007bff'
														}}
														aria-valuenow={tarea.animalesProcesados || 0}
														aria-valuemin="0"
														aria-valuemax={tarea.animalesDeseados || 0}
													></div>
												</div>
												<div style={{ width: '60px', textAlign: 'right' }}>
													<small>{tarea.animalesProcesados || 0}/{tarea.animalesDeseados || 0}</small>
												</div>
											</div>
										</td>
										<td>{tarea.fechaParaCuando ? getShortDisplayDate(tarea.fechaParaCuando) : 'Sin fecha'}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</Card.Body>
		</Card>
	)
}

export default TareasCard