import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import { toast } from 'react-toastify'
import '../configuraciones.css'

import api from 'api/api'
import { LoadingSpinner } from 'components/LoadingSpinner'
import Propietario from './Propietario'
import DeleteModal from './DeleteModal'
import TopHeader from 'components/TopHeader'

export default function Propietarios() {
	const [propietarios, setPropietarios] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [cancelToken, setCancelToken] = useState(null)
	const [editingPropietario, setEditingPropietario] = useState(null)
	const [deletingPropietario, setDeletingPropietario] = useState(null)

	const refreshPropietarios = async (cancelToken) => {
		try {
			setLoading(true)
			const { datos } = await api.get('u/propietarios', {
				cancelToken: cancelToken.token,
			})

			setPropietarios(
				datos.map((propietario) => ({
					id: propietario.id,
					nombre: propietario.nombre,
					apellido: propietario.apellido,
					codigo: propietario.codigo,
				}))
			)
		} catch (error) {
			if (axios.isCancel(error)) {
				console.log('Petición cancelada', error.message)
			} else {
				toast.error('Error de conexión')
				setError(error)
			}
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		const source = axios.CancelToken.source()
		setCancelToken(source)

		refreshPropietarios(source)

		return () => {
			if (cancelToken) {
				cancelToken.cancel('Petición cancelada')
			}
		}
	}, [])

	const handleNew = () => {
		setEditingPropietario({})
	}

	const handleEdit = (propietario) => {
		setEditingPropietario(propietario)
	}

	const handleDelete = (propietario) => {
		setDeletingPropietario(propietario)
	}

	const onCancelEdit = () => {
		setEditingPropietario(null)
	}

	const onCancelDelete = () => {
		setDeletingPropietario(null)
	}

	const onSave = async (datos) => {
		try {
			setLoading(true)
			if (datos.id) {
				const { datos: respuesta, msg } = await api.put('u/propietarios/' + datos.id, datos)
				toast.success(msg)
			} else {
				const { datos: respuesta, msg } = await api.post('u/propietarios', datos)
				toast.success(msg)
			}
			refreshPropietarios(cancelToken)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelEdit()
		}
	}

	const onDelete = async (propietarioId) => {
		try {
			setLoading(true)
			const { msg } = await api.delete('u/propietarios/' + propietarioId)
			refreshPropietarios(cancelToken)
			toast.success(msg)
		} catch (error) {
			toast.error('Error de conexión')
			setError(error)
		} finally {
			setLoading(false)
			onCancelDelete()
		}
	}

	// render
	return (
		<>
			<Container fluid id="propietarios" className="vh-100">
				<TopHeader
					text="Propietarios"
				/>
				<Row>
					<Col>
						<Button onClick={() => handleNew()} variant="primary">
							<i className="fa fa-plus"></i> Nuevo Propietario
						</Button>
					</Col>
				</Row>
				<Row>
					{loading ? (
						<Col>
							<LoadingSpinner />
						</Col>
					) : (
						<Col md={8} lg={6}>
							<Table className="mt-2" striped bordered hover>
								<thead>
									<tr className="text-secondary">
										<th>Id</th>
										<th>Nombre</th>
										<th>Apellido</th>
										<th>Código</th>
										<th>Acciones</th>
									</tr>
								</thead>
								<tbody>
									{propietarios.map((propietario) => {
										return (
											<tr key={propietario.id}>
												<td>{propietario.id}</td>
												<td>{propietario.nombre}</td>
												<td>{propietario.apellido}</td>
												<td>{propietario.codigo}</td>
												<td>
													<Button
														onClick={() => handleEdit(propietario)}
														className="me-2"
														variant="secondary"
														size="sm"
													>
														Editar
													</Button>
													<Button
														onClick={() => handleDelete(propietario)}
														variant="danger"
														size="sm"
													>
														Eliminar
													</Button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Col>
					)}
				</Row>
			</Container>
			{editingPropietario ? (
				<Propietario key={editingPropietario?.id || 0} propietario={editingPropietario} onSave={onSave} onCancel={onCancelEdit} />
			) : null}
			{deletingPropietario ? (
				<DeleteModal propietario={deletingPropietario} onDelete={onDelete} onCancel={onCancelDelete} />
			) : null}
		</>
	)
}
