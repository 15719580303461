import React from 'react'
import { Card } from 'react-bootstrap'
import { numberWithSeparator } from 'utils/numberWithSeparator'

// Función auxiliar para convertir números a string de manera segura
const safeNumberToString = (value) => {
	if (value === undefined || value === null) return '0'
	return value.toString()
}

const VentasCard = ({ ventasData, nombreEstablecimiento }) => {
	return (
		<Card className="mb-4 shadow-sm">
			<Card.Header className="bg-primary text-white">
				<h5 className="mb-0">
					Venta de Animales
					{nombreEstablecimiento && (
						<small className="ms-2">({nombreEstablecimiento})</small>
					)}
				</h5>
			</Card.Header>
			<Card.Body>
				{!ventasData || ventasData.length === 0 ? (
					<div className="alert alert-info">No hay datos de ventas disponibles para este establecimiento.</div>
				) : (
					<div className="table-responsive">
						<table className="table table-sm">
							<thead>
								<tr>
									<th>Categoría</th>
									<th className="text-center">Este año</th>
									<th className="text-center">Año pasado</th>
								</tr>
							</thead>
							<tbody>
								{ventasData.map((item, index) => {
									return (
										<tr key={index}>
											<td>{item.categoria || 'Desconocido'}</td>
											<td className="text-center fw-bold">{numberWithSeparator(safeNumberToString(item.anhoActual))}</td>
											<td className="text-center">{numberWithSeparator(safeNumberToString(item.anhoPasado))}</td>
										</tr>
									)
								})}
							</tbody>
							<tfoot className="table-light">
								<tr>
									<td className="fw-bold">Total</td>
									<td className="text-center fw-bold">
										{numberWithSeparator(safeNumberToString(
											ventasData.reduce((sum, item) => sum + (item.anhoActual || 0), 0)
										))}
									</td>
									<td className="text-center">
										{numberWithSeparator(safeNumberToString(
											ventasData.reduce((sum, item) => sum + (item.anhoPasado || 0), 0)
										))}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				)}
			</Card.Body>
		</Card>
	)
}

export default VentasCard