import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function TopHeader(props) {
	const { iconName, text } = props
	const icon = iconName ? (
		<i className={`fa fa-${iconName}`} style={{ fontSize: 28, marginLeft: 4, marginRight: 12, marginBottom: 6 }}></i>
	) : null

	return (
		<Row style={{ backgroundColor: 'var(--bs-verdeAlt', paddingTop: 4, paddingBottom: 12, color: 'white', marginBottom: 10 }}>
			<Col className="mt-0">
				<h1 className="mt-0 mb-0 d-flex align-items-end">{icon}{text}</h1>
			</Col>
		</Row>
	)
}