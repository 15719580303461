import { LOCALSTORAGE_KEY } from "./constants"

export const setItem = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value))
}

export const getItem = (key) => {
	const item = localStorage.getItem(key)
	if (item) {
		return JSON.parse(item)
	}
	return null
}

export const removeItem = (key) => {
	localStorage.removeItem(key)
}

export const saveLoginData = (loginData, hash) => {
	setItem(LOCALSTORAGE_KEY, {
		...loginData,
		hash
	})
}

export const getData = (key) => {
	const data = getItem(LOCALSTORAGE_KEY)
	if (data && data[key]) {
		return data[key]
	}
	return null
}
