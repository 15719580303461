/**
 * Calcula los totales combinando datos de todos los establecimientos
 * @param {Object} datosEstablecimientos - Objeto con los datos de todos los establecimientos
 * @returns {Object} - Objeto con los datos totales calculados
 */
export const calcularTotales = (datosEstablecimientos) => {
	const total = {
		nombre: "Total General",
		animales: {
			stock: [],
			reproduccion: {
				servidas: { anhoActual: 0, anhoPasado: 0 },
				prenadas: { anhoActual: 0, anhoPasado: 0 },
				nacidos: { anhoActual: 0, anhoPasado: 0 }
			},
			ventas: []
		}
	}

	// Mapa para agregar stock por categoría
	const stockPorCategoria = new Map()
	// Mapa para agregar ventas por categoría
	const ventasPorCategoria = new Map()

	// Debug
	console.log('Calculando totales para:', Object.keys(datosEstablecimientos))

	// Recorremos cada establecimiento
	for (const [id, datos] of Object.entries(datosEstablecimientos)) {
		// Saltamos la propiedad 'tareas' y aseguramos que los datos sean válidos
		if (id === 'tareas' || !datos || typeof datos !== 'object' || !datos.animales) {
			continue
		}

		console.log(`Procesando establecimiento ${id}:`,
			datos.nombre,
			'stock items:', datos.animales?.stock?.length || 0,
			'ventas items:', datos.animales?.ventas?.length || 0)

		// Sumar datos de reproducción
		if (datos.animales.reproduccion) {
			const repro = datos.animales.reproduccion

			// Servidas
			if (repro.servidas) {
				total.animales.reproduccion.servidas.anhoActual += (repro.servidas.anhoActual || 0)
				total.animales.reproduccion.servidas.anhoPasado += (repro.servidas.anhoPasado || 0)
			}

			// Preñadas
			if (repro.prenadas) {
				total.animales.reproduccion.prenadas.anhoActual += (repro.prenadas.anhoActual || 0)
				total.animales.reproduccion.prenadas.anhoPasado += (repro.prenadas.anhoPasado || 0)
			}

			// Nacidos
			if (repro.nacidos) {
				total.animales.reproduccion.nacidos.anhoActual += (repro.nacidos.anhoActual || 0)
				total.animales.reproduccion.nacidos.anhoPasado += (repro.nacidos.anhoPasado || 0)
			}
		}

		// Sumar stock por categoría
		if (datos.animales.stock && Array.isArray(datos.animales.stock)) {
			datos.animales.stock.forEach(item => {
				if (!item) return

				const categoriaId = item.id || (item.filtros ? item.filtros.categoriaId : null)
				const categoriaNombre = item.nombre
				const total = item.total || 0
				const color = item.color

				if (categoriaId && total > 0) {
					if (stockPorCategoria.has(categoriaId)) {
						const stockExistente = stockPorCategoria.get(categoriaId)
						stockExistente.total += total
					} else {
						stockPorCategoria.set(categoriaId, {
							id: categoriaId,
							nombre: categoriaNombre,
							total: total,
							color: color
						})
					}
				}
			})
		}

		// Sumar ventas por categoría
		if (datos.animales.ventas && Array.isArray(datos.animales.ventas)) {
			datos.animales.ventas.forEach(item => {
				if (!item) return

				const categoria = item.categoria
				const anhoActual = item.anhoActual || 0
				const anhoPasado = item.anhoPasado || 0

				if (categoria) {
					if (ventasPorCategoria.has(categoria)) {
						const ventaExistente = ventasPorCategoria.get(categoria)
						ventaExistente.anhoActual += anhoActual
						ventaExistente.anhoPasado += anhoPasado
					} else {
						ventasPorCategoria.set(categoria, {
							categoria: categoria,
							anhoActual: anhoActual,
							anhoPasado: anhoPasado
						})
					}
				}
			})
		}
	}

	// Convertir mapas a arrays
	total.animales.stock = Array.from(stockPorCategoria.values())
	total.animales.ventas = Array.from(ventasPorCategoria.values())

	return total
}

/**
 * Función auxiliar para convertir números a string de manera segura
 * @param {*} value - Valor a convertir
 * @returns {string} - String representando el número o "0" si es nulo o indefinido
 */
export const safeNumberToString = (value) => {
	if (value === undefined || value === null) return '0'
	return value.toString()
}