import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { numberWithSeparator } from 'utils/numberWithSeparator'

// Función auxiliar para convertir números a string de manera segura
const safeNumberToString = (value) => {
	if (value === undefined || value === null) return '0'
	return value.toString()
}

const ClimaCard = ({ climaData, nombreEstablecimiento }) => {
	const mesCorriente = climaData?.mesCorriente || {
		nombre: '',
		actual: 0,
		anhoPasado: 0,
		promedio: 0,
		min: 0,
		max: 0
	}

	const acumuladoAnho = climaData?.acumuladoAnho || 0
	const ultimos12Meses = climaData?.ultimos12Meses || { total: 0, periodo: '' }

	return (
		<Card className="shadow-sm">
			<Card.Header className="bg-primary text-light d-flex justify-content-between align-items-center">
				<h5 className="mb-0">
					Clima
					{nombreEstablecimiento && (
						<small className="ms-2">({nombreEstablecimiento})</small>
					)}
				</h5>
				<a href="/clima" className="btn btn-sm btn-outline-light">
					<i className="fa fa-cloud-rain me-1"></i> Ver más
				</a>
			</Card.Header>
			<Card.Body>
				<Row className="d-flex justify-content-center">
					{/* Precipitaciones del mes */}
					<Col lg={3} md={6} className="mb-3 mb-lg-0">
						<div className="card h-100 border-0">
							<div className="card-body text-center p-2">
								<div className="rainfall-circle mx-auto mb-3 d-flex flex-column align-items-center justify-content-center bg-light rounded-circle" style={{ width: '120px', height: '120px' }}>
									<h3 className="mb-0">{numberWithSeparator(safeNumberToString(mesCorriente.actual))}</h3>
									<small>mm</small>
								</div>
								<h6 className="mb-1 text-primary">Precipitaciones este mes</h6>
								<div style={{ lineHeight: 1.1 }}>
									<div><small className="text-muted">Año anterior: {numberWithSeparator(safeNumberToString(mesCorriente.anhoPasado))} mm</small></div>
									<div><small className="text-muted">Prom. histórico: {numberWithSeparator(safeNumberToString(mesCorriente.promedio.toFixed(0)))} mm</small></div>
								</div>
							</div>
						</div>
					</Col>

					{/* Acumulado del año */}
					<Col lg={3} md={6} className="mb-3 mb-lg-0">
						<div className="card h-100 border-0">
							<div className="card-body text-center p-2">
								<div className="rainfall-circle mx-auto mb-3 d-flex flex-column align-items-center justify-content-center bg-light rounded-circle" style={{ width: '120px', height: '120px' }}>
									<h3 className="mb-0">{numberWithSeparator(safeNumberToString(acumuladoAnho))}</h3>
									<small>mm</small>
								</div>
								<h6 className="mb-1 text-primary">Acumulado del año</h6>
							</div>
						</div>
					</Col>

					{/* Últimos 12 meses */}
					<Col lg={3} md={6}>
						<div className="card h-100 border-0">
							<div className="card-body text-center p-2">
								<div className="rainfall-circle mx-auto mb-3 d-flex flex-column align-items-center justify-content-center bg-light rounded-circle" style={{ width: '120px', height: '120px' }}>
									<h3 className="mb-0">{numberWithSeparator(safeNumberToString(ultimos12Meses.total))}</h3>
									<small>mm</small>
								</div>
								<div className="text-center mt-2">
									<h6 className="mb-1 text-primary">Últimos 12 meses</h6>
									<i className="fa fa-calendar-alt text-muted me-1"></i>
									<small className="text-muted">
										{ultimos12Meses.periodo || ''}
									</small>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default ClimaCard